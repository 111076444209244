import { SHA256 } from 'crypto-js';
import React from 'react'
import axios from 'axios';
import { Buffer } from 'buffer'
const Test = () => {
    const handleSubmit = async (e) => {
        e.preventDefault()
        const payload = {
            "merchantId": "PGTESTPAYUAT",
            "merchantTransactionId": "MT7850590068188104",
            "merchantUserId": "MUID123",
            "amount": e.target[0].value,
            "redirectUrl": "https://www.ludogamers.com//transaction",
            "redirectMode": "REDIRECT",
            "callbackUrl": "https://www.ludogamers.com//transactions",
            "mobileNumber": e.target[1].value,
            "paymentInstrument": {
                "type": "PAY_PAGE"
            }
        };

        const dataPayload = JSON.stringify(payload);

        // SHA256(base64 encoded payload + "/pg/v1/pay" + salt key) + ### + salt index

        const dataBase64 = Buffer.from(dataPayload).toString("base64");
        // eslint-disable-next-line 
        const fullURL = dataBase64 + "/pg/v1/pay" + "099eb0cd-02cf-4e2a-8aca-3e6c6aff0399";
        const checksum = SHA256(fullURL) + "###" + 1




        const response = await axios.post(
            "https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay",
            // "https://api-preprod.phonepe.com/apis/pg-sandbox",
            {
                request: dataBase64,
            },
            {
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                    "X-VERIFY": checksum
                },
            }
        ).catch((err) => console.log(err.message))
        // console.log(response)
        if (response) {
            const redirectUrl = response?.data?.data?.instrumentResponse?.redirectInfo?.url
            window.location.replace(redirectUrl);
        }
    }
    return (
        <div>
            <form className='form-control w-50' onSubmit={handleSubmit}>
                <div>
                    <label>Amount</label>
                    <input type="number" className='form-control' placeholder='Amount' />
                </div>
                <div>
                    <label>Mobile</label>
                    <input type="number" className='form-control' placeholder='mobile number' />
                </div>
                <div>
                    <button type='submit' className='btn bnt-lg btn-red mt-5'>Submit</button>
                </div>
            </form>
        </div>
    )
}

export default Test