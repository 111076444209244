import React, { Fragment, useEffect, useState } from "react";
import Section from "./layouts/Section";

import { useDispatch, useSelector } from "react-redux";
import url from "../environemt";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import { addUser } from "../store/slicer";
import { Navigate } from "react-router";
import Popup from "reactjs-popup";

const UserSetting = () => {
  const [edit, setEdit] = useState(false);

  const userData = useSelector((state) => state.user.userData);
  // eslint-disable-next-line
  const [cookies, setCookie, removeToken] = useCookies(["ludoT"]);
  const [error, setError] = useState([]);
  const [userMatrix, setUserMatrix] = useState();
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  var token = cookies.ludoM;
  const logout = () => {
    removeToken("ludoM", { path: "/" });
    removeToken("ludoT", { path: "/" });
    removeToken("sg2", { path: "/" });
    removeToken("sg1", { path: "/" });
    localStorage.removeItem("acno");
    localStorage.removeItem("lastWithdrawCall");
    return <Navigate to="/" />;
  };
  const updateUsername = async (e) => {
    e.preventDefault();
    const data = {
      username: e.target[0].value,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(`${url}users_update`, requestOptions);
      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.data[0]) {
          toast.error(errorData.data[0]);
        }
      }
      let actualData = await response.json();
      dispatch(addUser(actualData.data));
      if (actualData.success) {
        toast.success("UserName Updated Successfully");
      }
    } catch (err) {
      setError(err.message);
      // console.log(error);
    } finally {
      setLoading(false);
      setEdit(!edit);
    }
  };
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: signal,
    };
    const getData = async () => {
      try {
        const response = await fetch(`${url}userSetting`, requestOptions);
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        let actualData = await response.json();
        setUserMatrix(actualData.data);
        setError(null);
      } catch (err) {
        setError(err.message);
        // console.log(error);
      } finally {
        setLoading(false);
      }
    };
    getData();
    return () => controller.abort();
    // eslint-disable-next-line
  }, []);
  return (
    <Section allNotification={false} searchPopup={true} title={"User Setting"}>
      {loading ? (
        <div className="m-auto w-100 d-flex justify-content-center align-items-center h-60vh">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <div
            className="d-flex p-2 justify-content-center trans-bg px-3 align-items-center bg-white border  mx-3 border-3"
            style={{ borderRadius: "1.25rem", marginTop: "70px" }}
          >
            <h1 className="text-dark"> Profile</h1>
          </div>

          <div className=" col-12 mx-auto g-0 iframe-sec p-3">
            <div className="mb-3 shadow card">
              <div className="card-body">
                <div className="d-flex flex-column align-items-start justify-content-center mb-3">
                  <label className="form-label text-capitalize">username</label>
                  <div
                    className="align-self-stretch d-flex align-items-center"
                    style={{ gap: "5px" }}
                  >
                    {!edit ? (
                      <Fragment>
                        <input
                          type="text"
                          className="form-control me-2"
                          maxLength={10}
                          disabled
                          defaultValue={userData.username && userData.username}
                          style={{ width: "75%" }}
                        />
                        <button
                          className="btn btn-primary text-capitalize btn-sm align-self-stretch rounded-1"
                          style={{
                            width: 75,
                            height: "37px",
                            lineHeight: "34px",
                          }}
                          onClick={() => setEdit(!edit)}
                        >
                          edit
                        </button>
                      </Fragment>
                    ) : (
                      <form
                        onSubmit={updateUsername}
                        className="align-self-stretch d-flex align-items-center"
                        style={{ gap: "5px" }}
                      >
                        <input
                          type="text"
                          className="form-control me-2"
                          maxLength={10}
                          defaultValue={userData.username && userData.username}
                        />
                        <button
                          c
                          className="btn btn-primary text-capitalize btn-sm align-self-stretch rounded-1"
                          style={{
                            width: 75,
                            height: "37px",
                            lineHeight: "34px",
                          }}
                          type="submit"
                        >
                          save
                        </button>
                      </form>
                    )}
                  </div>
                </div>
                <div className="d-flex flex-column align-items-start justify-content-center mb-3">
                  <label className="form-label text-capitalize">
                    mobile number
                  </label>
                  <div className="align-self-stretch">
                    <input
                      type="number"
                      className="form-control"
                      readOnly
                      disabled
                      defaultValue={userData.phone_no && userData.phone_no}
                    />
                  </div>
                </div>
                <div className={`d-flex flex-row align-items-center justify-content-between p-3 ${userData?.aname ? "border-success" : "border-danger"} card bg-outline text-dark`}>
                  <div className="d-flex flex-column align-items-start justify-content-center">
                    <span style={{ fontSize: "0.8rem" }}>KYC status</span>
                    <span className="fw-bold text-capitalize">
                      {!userData?.aname && "not"} verfied{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        width={16}
                        height={16}
                        fill={userData?.aname ? "green" : "red"}
                      >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                      </svg>
                    </span>
                  </div>
                  <div>
                    {userData?.aname &&
                      <Popup
                        className="bottom-0 top-auto custome-css"
                        trigger={
                          <button className="btn btn-outline-success btn-sm text-capitalize text-dark"

                          >
                            view kyc details
                          </button>
                        }
                        modal
                      >
                        {(close) => (
                          <div className="w-100">
                            <div className="">
                              <h5 className="text-center bg-grey rounded py-1">Kyc Details</h5>
                            </div>
                            <table className="table">
                              <tr><td>
                                Name
                              </td><td>
                                  {userData?.aname}
                                </td>
                              </tr>
                              <tr><td>
                                Adhaar number
                              </td><td>
                                  {userData?.adhar_no}
                                </td>
                              </tr>
                              <tr><td>
                                Dob
                              </td><td>
                                  {userData?.dateOfBirth}
                                </td>
                              </tr>
                              <tr><td>
                                Address
                              </td><td>
                                  {userData?.address}
                                </td>
                              </tr>
                            </table>
                            <button className="text-dark btn btn-light" onClick={close}>
                              close
                            </button>
                          </div>
                        )}
                      </Popup>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3 shadow card">
              <div className="bg-light text-dark card-header">Metrics</div>
              <div className="card-body">
                <div className="g-0 gx-2 mb-2 row">
                  <div className="col">
                    <div className="d-flex flex-column align-items-stretch justify-content-start h-100 w-100 card">
                      <div
                        className="text-capitalize text-start px-2 card-header"
                        style={{ fontSize: "0.9rem" }}
                      >
                        <div className="hstack gap-1">
                          <span>games played</span>
                        </div>
                      </div>
                      <div className="fs-5 fw-semibold text-start py-1 px-2 card-body">
                        {userMatrix?.total_game ? userMatrix?.total_game : "0"}
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="d-flex flex-column align-items-stretch justify-content-start h-100 w-100 card">
                      <div
                        className="text-capitalize text-start px-2 card-header"
                        style={{ fontSize: "0.9rem" }}
                      >
                        <div className="hstack gap-1">
                          <span>Coins won</span>
                        </div>
                      </div>
                      <div className="fs-5 fw-semibold text-start py-1 px-2 card-body">
                        {userMatrix?.wallet_winning
                          ? userMatrix?.wallet_winning.toFixed(2)
                          : "0"}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="g-0 gx-2 row">
                  <div className="col">
                    <div className="d-flex flex-column align-items-stretch justify-content-start h-100 w-100 card">
                      <div
                        className="text-capitalize text-start px-2 card-header"
                        style={{ fontSize: "0.9rem" }}
                      >
                        <div className="hstack gap-1">
                          <span>referral earning</span>
                        </div>
                      </div>
                      <div className="fs-5 fw-semibold text-start py-1 px-2 card-body">
                        {userMatrix?.referalEarnings
                          ? userMatrix?.referalEarnings.TtoFixed(2)
                          : "0"}
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="d-flex flex-column align-items-stretch justify-content-start h-100 w-100 card">
                      <div
                        className="text-capitalize text-start px-2 card-header"
                        style={{ fontSize: "0.9rem" }}
                      >
                        <div className="hstack gap-1">
                          <span>Penalty</span>
                        </div>
                      </div>
                      <div
                        className="fs-5 fw-semibold text-start py-1 px-2 card-body p-1"
                        style={{ height: "30px" }}
                      >
                        {userMatrix?.total_penalty
                          ? userMatrix?.total_penalty
                          : "0"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-grid py-2">
              <button
                type="button"
                className="text-capitalize btn btn-outline-danger border bg-red fw-bold text-dark"
                onClick={logout}
              >
                logout
              </button>
            </div>
          </div>
        </>
      )}

    </Section>
  );
};

export default UserSetting;
