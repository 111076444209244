import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userData: {},
  userWallet: {},
  userWalletWith: {},
  openRoom: {},
  Spin: {},
  roomUpdate: false,
  upiId: "",
  roomCode: ""
};
export const userSlicer = createSlice({
  name: "user",
  initialState,
  reducers: {
    addUser: (state, action) => {
      state.userData = action.payload;
    },
    addWallet: (state, action) => {
      state.userWallet = action.payload;
    },
    addWithdraw: (state, action) => {
      state.userWalletWith = action.payload;
    },
    addOpenRoom: (state, action) => {
      state.openRoom = action.payload;
    },
    addSpin: (state, action) => {
      state.Spin = action.payload;
    },
    roomstateUpdate: (state, action) => {
      state.roomUpdate = action.payload;
    },
    addUpiId: (state, action) => {
      state.upiId = action.payload
    },
    addRoomCode: (state, action) => {
      state.roomCode = action.payload
    }
  },
});
export const {
  addUser,
  addWallet,
  addWithdraw,
  addOpenRoom,
  addSpin,
  roomstateUpdate,
  addUpiId,
  addRoomCode
} = userSlicer.actions;

export default userSlicer.reducer;
