import React, { Component } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

const Navigation = () => {
  const path = useLocation();
  // const naviagte = useHistory();
  // const handleBack = () => {
  //   if (path.pathname === "/games") {
  //     naviagte.push("/");
  //   } else {
  //     naviagte.goBack();
  //   }
  // };

  return (
    <>
      {path.pathname !== "/" &&
        path.pathname !== "/signin" &&
        path.pathname !== "/signup" &&
        path.pathname !== "/otp" &&
        path.pathname !== "/signup2" &&
        path.pathname !== "/otplogin" &&
        path.pathname !== "/adhaarotp" && (
          <div className="col-sm-4 col-4 text-dark">
            <div className="menu-bar text-dark">
              <i className="fa fa-bars" />
            </div>
          </div>
        )}
    </>
  );
};
const Avtar = () => {

  const path = useLocation();
  const user = useSelector((user) => user.user);
  const wallet = (user?.userWallet?.sumin - user?.userWallet?.sumout)?.toFixed(
    0
  );
  return (
    <>
      {path.pathname !== "/" &&
        path.pathname !== "/signin" &&
        path.pathname !== "/signup" &&
        path.pathname !== "/otp" &&
        path.pathname !== "/otplogin" &&
        path.pathname !== "/signup2" &&
        path.pathname !== "/adhaarotp" && (
          <Link to="/wallet">
            <li className="mt-2 ">
              <div className="py-1 bg-white border px-2 text-dark d-flex align-items-center rounded-1 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="1em"
                  height="1em"
                  fill="green"
                  className="me-2"
                >
                  <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2h-13z"></path>
                  <path d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6z"></path>
                </svg>
                <strong className="ml-2" style={{ fontSize: "0.70rem" }}>
                  {wallet}
                </strong>
              </div>
            </li>
          </Link>
        )}
    </>
  );
};
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { search: false };
  }

  render() {
    const searchPopup = (
      <>
        <div
          className={this.state.search ? "body-overlay active" : "body-overlay"}
          id="body-overlay"
          onClick={() => this.setState({ search: false })}
        />
        <div
          className={this.state.search ? "search-popup active" : "search-popup"}
          id="search-popup"
        >
          <form className="search-form">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search....."
              />
            </div>
            <button type="button" className="submit-btn">
              <i className="fa fa-search" />
            </button>
          </form>
        </div>
      </>
    );

    return (
      <>
        {this.props.searchPopup ? searchPopup : ""}
        <div
          className="header-area bg-grey-custome"
          style={{ backgroundImage: "url(./assets/img/bg/1.png)" }}
        >
          <div className="container">
            <div className="row d-flex justify-content-between">
              <div className="col-sm-4 col-3 d-flex">
                <Navigation />
              </div>
              <div className="col-sm-4 col-6 text-center logo-img">
                <Link
                  to={"/"}
                  className="logo d-flex align-items-center justify-content-center"
                >
                  <img
                    style={{ width: "47%" }}
                    src={
                      process.env.PUBLIC_URL + "/assets/img/ludo-icon-small.png"
                    }
                    alt="logo"
                    className="logo-img"
                  />
                </Link>
              </div>
              <div
                className="col-sm-4 col-3 text-right"
                style={{ paddingLeft: "0px" }}
              >
                <ul className="header-right mr-2">
                  <Avtar />
                </ul>
              </div>
              {/* <div className="col-sm-4 col-3 text-right">
                                {this.props.searchPopup ? (
                                    <div className="search header-search" onClick={() => this.setState({ search: true })}>
                                        <i className="fa fa-search" />
                                    </div>
                                ) : ''}
                            </div> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Header;
