import React from "react";

const CoinsImg = () => {
  return (
    <img
      src={process.env.PUBLIC_URL + "/assets/img/coin.png"}
      alt="coins"
      style={{
        width: "17px",
        height: "17px",
        marginRight: "4px",
        marginTop: "1px",
      }}
    />
  );
};

export default CoinsImg;
