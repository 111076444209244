import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import history from "./History";
import Home from "./components/Home";
import About from "./components/About";

import Contact from "./components/Contact";
import Signin from "./components/Signin";
import Signup from "./components/Signup";
import Transaction from "./components/Transaction";

import UserSetting from "./components/UserSetting";
import UserVerification from "./components/UserVerification";
import Gamelist from "./components/GameList";
import History from "./components/History";
import TermandLegal from "./components/TermandLegal";
import Games from "./components/Game";
import TransactionHistory from "./components/TrancstionHistory";
import WalletHistory from "./components/WalletHistory";
import Roomcode from "./components/Roomcode";
import Landing from "./components/Landing";
import GamelistRich from "./components/GameListrich";
import Support from "./components/support";
import RefferalAndEarn from "./components/RefferalAndEarn";
import TransactionF from "./components/TransactionsF";
import SpinWheel from "./components/SpinWheel";
import RoomCodeOpen from "./components/RoomcodeOpen";
import SignUpAdhaar from "./components/SignupAdhaar";
import SignUPAdhaarOtp from "./components/SignupAdhaarOtp";
import UserVerificationLogin from "./components/UserVerificationLogin";
import GamePlayDirect from "./components/GamePlayDirect";
import ManualRoomCode from "./components/ManualRoomCode";
import ManualRoomCodeuser2 from "./components/ManualRoomCodeUser2";
import Wallet from "./components/Wallet";
import Test from "./Test";
class Routes extends Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path={"/"} render={(props) => <Landing {...props} />} />
          <Route exact path={"/home"} render={(props) => <Home {...props} />} />
          <Route
            exact
            path={"/about"}
            render={(props) => <About {...props} />}
          />

          {/*<Route exact path={'/components-tooltip'} render={(props) => (<ComponentsTooltip {...props} />)} />*/}
          <Route
            exact
            path={"/contact"}
            render={(props) => <Contact {...props} />}
          />

          <Route
            exact
            path={"/signin"}
            render={(props) => <Signin {...props} />}
          />
          <Route
            exact
            path={"/signup/:refer"}
            render={(props) => <Signup {...props} />}
          />
          <Route
            exact
            path={"/signup"}
            render={(props) => <Signup {...props} />}
          />
          <Route
            exact
            path={"/signup2"}
            render={(props) => <SignUpAdhaar {...props} />}
          />
          <Route
            exact
            path={"/transaction/:transactionId"}
            render={(props) => <Transaction {...props} />}
          />
          <Route
            exact
            path={"/transactions"}
            render={(props) => <TransactionF {...props} />}
          />

          <Route
            exact
            path={"/user-setting"}
            render={(props) => <UserSetting {...props} />}
          />
          <Route
            exact
            path={"/otp"}
            render={(props) => <UserVerification {...props} />}
          />
          <Route
            exact
            path={"/otplogin"}
            render={(props) => <UserVerificationLogin {...props} />}
          />
          <Route
            exact
            path={"/adhaarotp"}
            render={(props) => <SignUPAdhaarOtp {...props} />}
          />

          <Route
            exact
            path="/referal-earn"
            render={(props) => <RefferalAndEarn {...props} />}
          />
          <Route
            exact
            path="/support"
            render={(props) => <Support {...props} />}
          />
          <Route
            exact
            path="/game-rich/:mode"
            render={(props) => <GamelistRich {...props} />}
          />
          <Route
            exact
            path="/game-list/:mode"
            render={(props) => <Gamelist {...props} />}
          />
          <Route
            exact
            path="/history"
            render={(props) => <History {...props} />}
          />
          <Route
            exact
            path="/transaction-history"
            render={(props) => <TransactionHistory {...props} />}
          />
          <Route
            exact
            path="/wallet-history"
            render={(props) => <WalletHistory {...props} />}
          />
          <Route
            exact
            path="/term-legal"
            render={(props) => <TermandLegal {...props} />}
          />
          <Route exact path="/games" render={(props) => <Games {...props} />} />
          <Route
            exact
            path="/games-play/:mode"
            render={(props) => <GamePlayDirect {...props} />}
          />
          <Route
            exact
            path="/room-code/:id/:mode"
            render={(props) => <Roomcode {...props} />}
          />
          <Route
            exact
            path="/spin-wheel"
            render={(props) => <SpinWheel {...props} />}
          />
          <Route
            exact
            path="/room-code/:id/:mode/:by"
            // path="/room-code/playgame"
            render={(props) => <RoomCodeOpen {...props} />}
          />
          <Route
            exact
            path="/roomcode/manual/:by"
            // path="/room-code/playgame"
            render={(props) => <ManualRoomCode {...props} />}
          />
          <Route
            exact
            path="/roomcode/manual/:by/user2"
            // path="/room-code/playgame"
            render={(props) => <ManualRoomCodeuser2 {...props} />}
          />
          <Route
            exact
            path="/wallet"
            // path="/room-code/playgame"
            render={(props) => <Wallet {...props} />}
          />
          <Route
            exact
            path="/test"
            // path="/room-code/playgame"
            render={(props) => <Test {...props} />}
          />
        </Switch>
      </Router>
    );
  }
}

export default Routes;
