import React, { useEffect, useState } from "react";
import url from "../environemt";
import Section from "./layouts/Section";
import { useCookies } from "react-cookie";
import CoinsImg from "./CoinsImg";
const TransactionHistory = () => {
  // eslint-disable-next-line
  const [cookies] = useCookies();
  const [tranactions, setTranactions] = useState([]);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = cookies.ludoM;
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: signal,
    };
    const getData = async () => {
      try {
        const response = await fetch(`${url}mrg_wallet`, requestOptions);
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        //setLoading(false);
        const actualData = await response.json();
        const newArr = [];
        // console.log(actualData.data);
        /////////////shailender code
        const newItem = actualData.data;
        for (let i = 0; i < newItem.length - 1; i++) {
          if (newItem[i].txn_id === newItem[i + 1].txn_id) {
            if (newItem[i].in_out === "O" && newItem[i + 1].in_out === "O") {
              newArr.push({
                ...newItem[i],
                pay_amnt: newItem[i].pay_amnt + newItem[i + 1].pay_amnt,
              });
              // console.log(newItem[i], newItem[i + 1]);
              i++;
            } else if (
              newItem[i].in_out === "IN" &&
              newItem[i + 1].in_out === "IN"
            ) {
              newArr.push({
                ...newItem[i],
                pay_amnt: newItem[i].pay_amnt + newItem[i + 1].pay_amnt,
              });
              i++;
            } else {
              newArr.push(newItem[i]);
            }
          } else {
            newArr.push(newItem[i]);
          }
        }
        setTranactions(newArr);
        /////////////shailender code
      } catch (err) {
        setError(err.message);
        setTranactions([]);
      } finally {
        setLoading(false);
      }
    };
    getData();
    // console.log(error);
    return () => controller.abort();
    // eslint-disable-next-line
  }, []);
  return (
    <React.Fragment>
      <Section>
        <div
          className="d-flex p-2 justify-content-center trans-bg px-3 align-items-center bg-white border  mx-3 border-3"
          style={{ borderRadius: "1.25rem", marginTop: "70px" }}
        >
          <h1 className="text-dark">Game History</h1>
        </div>
        {loading ? (
          <div className="m-auto w-100 d-flex justify-content-center align-items-center h-60vh">
            <div className="loader"></div>
          </div>
        ) : (
          <div
            className="mt-3  mx-3 border overflow-hidden"
            style={{ borderRadius: "1.25rem", overflow: "hidden" }}
          >
            {tranactions?.map((item, i) => (
              <div
                className={
                  item.payment_status === "failure"
                    ? "single-goal red single-goal-one green-transaction "
                    : item.payment_status === "processed"
                      ? "single-goal yellow single-goal-one"
                      : "single-goal green single-goal-one green-transaction"
                }
                key={i}
                id={i}
              >
                <div
                  className={
                    item.in_out === "IN" && item.payment_status !== "failure"
                      ? "row green-transaction"
                      : "row red-transaction"
                  }
                  style={{ height: "60px" }}
                >
                  <div className="col-7 pr-0">
                    <div
                      className="details"
                      style={{ padding: "3px 0px 3px 18px" }}
                    >
                      <h6 className="mr-1 text-font-14px mb-0">
                        {item.amount_from}
                      </h6>

                      <p
                        className={
                          item.in_out === "IN" &&
                            item.payment_status !== "failure"
                            ? "text-success"
                            : "text-danger"
                        }
                        style={{ fontSize: "0.7rem" }}
                      >
                        {item.amount_from === "WITHDRAW REJECTED" ||
                          item.amount_from === "WITHDRAW PAID" ||
                          item.amount_from === "WITHDRAW REQUEST" ||
                          item.amount_from === "COINS ADDED" ||
                          item.amount_from === "SPIN WHEEL WINNING"
                          ? ""
                          : item.txn_id.split("LG")[1]
                            ? `ROOM CODE : ${item.txn_id.split("LG")[1]}`
                            : ` ID : ${item.txn_id}`}
                      </p>
                      <p>{item.amount_date}</p>
                    </div>
                  </div>
                  <div className="col-5 pl-0">
                    <div
                      className="circle-inner circle-inner-one d-flex flex-column"
                      style={{ width: "135px", padding: "22px 0px" }}
                    >
                      {item.in_out === "IN" ? (
                        <React.Fragment>
                          {item.payment_status === "processed" ? (
                            <React.Fragment>
                              <CoinsImg />
                              <h6 className="goal-amount mb-2 text-yellow">
                                {item.pay_amnt.toFixed(2)}
                              </h6>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <CoinsImg />
                              <h6
                                className={
                                  item.payment_status === "failure"
                                    ? "goal-amount mb-2 text-danger"
                                    : "goal-amount mb-2 text-success"
                                }
                              >
                                {item.pay_amnt.toFixed(2)}
                              </h6>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {item.payment_status === "processed" ? (
                            <React.Fragment>
                              <CoinsImg />
                              <h6 className="goal-amount mb-2 text-yellow">
                                -{item.pay_amnt.toFixed(2)}
                              </h6>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <CoinsImg />
                              <h6 className="goal-amount mb-2 text-danger">
                                -{item.pay_amnt.toFixed(2)}
                              </h6>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </Section>
    </React.Fragment>
  );
};

export default TransactionHistory;
