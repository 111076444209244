import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { store } from './store/store'
import { Provider } from 'react-redux'
import { CookiesProvider } from 'react-cookie';
import 'reactjs-popup/dist/index.css';
import './index.css';
// eslint-disable-next-line 
import styles from "./index.css"
ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider defaultSetCookies={{ path: '/' }}>
      <Provider store={store}>
        <App />
        <ToastContainer />
      </Provider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
