import React from "react";
import Section from "./layouts/Section";
import TermLegal from "./TermLegal";
import TermRefund from './TermRefund'

const TermandLegal = () => {
  return (
    <>
      <Section>
        <div className="col-xl-12" style={{ marginTop: "70px" }}>
          {/* Begin Icons 01 */}
          <div id="accordion-icon-left" className="accordion-icon icon-01">
            {/* Begin Widget */}
            <div className="widget has-shadow">
              <div className="widget-header bordered no-actions d-flex align-items-center justify-content-center">
                <h4> Term and Condition </h4>
              </div>
              <a
                className="card-header  d-flex align-items-center"
                data-toggle="collapse"
                href="#IconLeftCollapseOne"
              >
                <div className="card-title">Terms & Conditions </div>
              </a>
              <div
                id="IconLeftCollapseOne"
                className="card-body collapse pt-0 show"
                data-parent="#accordion-icon-left"
              >
                <TermLegal />

              </div>

            </div>
            {/* End Widget */}
          </div>
          <div id="accordion-icon-left" className="accordion-icon icon-01">
            {/* Begin Widget */}
            <div className="widget has-shadow">

              <a
                className="card-header  d-flex align-items-center"
                data-toggle="collapse"
                href="#IconLeftCollapseTwo"
              >
                <div className="card-title">Refund and Policy </div>
              </a>
              <div
                id="IconLeftCollapseTwo"
                className="card-body collapse pt-0 "
                data-parent="#accordion-icon-left"
              >
                <TermRefund />

              </div>

            </div>
            {/* End Widget */}
          </div>
          {/* <div id="accordion-icon-left" className="accordion-icon icon-01">
            {/* Begin Widget 
            <div className="widget has-shadow">

              <a
                className="card-header  d-flex align-items-center"
                data-toggle="collapse"
                href="#IconLeftCollapseThree"
              >
                <div className="card-title">Privacy and Policy </div>
              </a>
              <div
                id="IconLeftCollapseThree"
                className="card-body collapse pt-0 "
                data-parent="#accordion-icon-left"
              >
                

              </div>

            </div>
            
        </div> */}
          {/* End Icons 01 */}
        </div>
      </Section >
    </>
  );
};

export default TermandLegal;
