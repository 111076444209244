import { toast } from "react-toastify";
import url from "../environemt";


const ToastError = (text) => {
    return toast.error(text)
}
export const GetData = async (endpoint, setLoading, signal, token) => {
    try {
        setLoading(true);
        const requestOptions = {
            headers: { Authorization: `Bearer ${token}` },
            signal: signal,
        };
        const response = await fetch(`${url}${endpoint}`, requestOptions);
        if (!response.ok) {
            let actualData = await response.json();
            ToastError(actualData.message);
        }
        let actualData = await response.json();
        if (actualData.data) {
            return actualData.data;
        } else {
            return actualData;
        }
    } catch (err) {
        // console.log(err.message);
    } finally {
        setLoading(false);
    }
};
export const PostData = async (endpoint, setLoading, signal, data, token) => {
    try {
        setLoading(true);
        const requestOptions = {
            method: "POST",
            headers: { Authorization: `Bearer ${token}` },
            signal: signal,
            body: JSON.stringify(data),
        };
        const response = await fetch(`${url}${endpoint}`, requestOptions);
        if (!response.ok) {
            let actualData = await response.json();
            ToastError(actualData.message);
        }
        let actualData = await response.json();
        if (actualData.data) {
            return actualData.data;
        }
    } catch (err) {
        // console.log(err.message);
    } finally {
        setLoading(false);
    }
};
export const PutData = async (endpoint, setLoading, signal, data, token) => {
    try {
        setLoading(true);
        const requestOptions = {
            method: "PUT",
            headers: { Authorization: `Bearer ${token}` },
            signal: signal,
            body: JSON.stringify(data),
        };
        const response = await fetch(`${url}${endpoint}`, requestOptions);
        if (!response.ok) {
            let actualData = await response.json();
            ToastError(actualData.message);
        }
        let actualData = await response.json();
        if (actualData.data) {
            return actualData.data;
        }
    } catch (err) {
        // console.log(err.message);
    } finally {
        setLoading(false);
    }
};