import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import url from "../environemt";
import Section from "./layouts/Section";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import "./signup.css";
import { FiRefreshCcw } from "react-icons/fi";
import { useSelector } from "react-redux";

const SignUpAdhaar = () => {
  const navigate = useHistory();
  const [cookies, setCookie] = useCookies(["ludoT", "ludoM"]);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [secondLoading, setSecondLoading] = useState(true);
  const adhaarCard = localStorage.getItem("acno");
  const [adhaarValue, setAdhaarValue] = useState(adhaarCard);
  const [sessionData, setSessionData] = useState({});
  const [sessionDataCapctca, setSessionDataCapctca] = useState({});

  // eslint-disable-next-line
  useEffect(() => {
    try {
      if (cookies.sg1 !== 1) {
        navigate.push("/signup");
      } else if(adhaarCard){
        navigate.push("/home");
      } else if(!cookies.ludoM){
        navigate.push("/signin");
      }
    } catch (err) {
      // console.log(err);
    }
  }, []);
  const [no, token] = cookies.ludoT.split("|");
  const handleLoginsubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const data = {
      adhaar: e.target[0].value,
      session: sessionData.session_id,
      captcha: e.target[1].value,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(`${url}adhaar_otp`, requestOptions);
      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.data.error);
      }
      let actualData = await response.json();
      if (actualData.data.code === 200) {
        setCookie("sga", "1");
        toast.success(actualData.data.message);
        localStorage.setItem("AadharData", JSON.stringify(data));
        localStorage.setItem("ses", sessionData.session_id);
        navigate.push("/adhaarotp");
      } else {
        toast.error(actualData.data.message);
      }
    } catch (err) {
      setError(err.message);
      // console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const handleMaxLength = (e) => {
    if (e.target.value.length > e.target.maxLength) {
      setAdhaarValue(e.target.value.slice(0, e.target.maxLength));
      // localStorage.setItem("acno", e.target.value.slice(0, e.target.maxLength));
    } else {
      setAdhaarValue(e.target.value);
      // localStorage.setItem("acno", e.target.value);
    }
  };
  useEffect(() => {
    const getData = async () => {
      const requestOptions = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await fetch(`${url}get_concent`, requestOptions);
        if (!response.ok) {
          toast.error("some eror occured please try again");
        }
        let actualData = await response.json();
        setSessionData(actualData.data);
        setSessionDataCapctca(actualData.data.captcha);
      } catch (err) {
        setError(err.message);
      } finally {
        setSecondLoading(false);
      }
    };
    getData();
    setAdhaarValue(adhaarCard);
    // eslint-disable-next-line
  }, []);

  const handleRefreshCapcta = async () => {
    const data = {
      session: sessionData.session_id,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(`${url}rcaptcha`, requestOptions);
      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.data.error);
      }
      let actualData = await response.json();
      if (actualData.data.code === 200) {
        setSessionDataCapctca(actualData.data.data.captcha);
      } else {
        toast.error(actualData.data.message);
      }
    } catch (err) {
      setError(err.message);
      // (error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Section
      allNotification={false}
      searchPopup={true}
      title={"Signup"}
      sign={true}
    >
      <div
        className="ba-page-name text-center mg-bottom-40 black-gradient"
        style={{ marginTop: "1rem" }}
      >
        <h3 className="">Aadhaar Verification</h3>
      </div>
      {secondLoading ? (
        <div className="m-auto w-100 d-flex justify-content-center align-items-center h-60vh">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="signin-area mg-bottom-35">
          <div className="container">
            <form className="contact-form-inner" onSubmit={handleLoginsubmit}>
              <label className="single-input-wrap">
                <span>Aadhaar Number </span>
                <input
                  type="number"
                  value={adhaarValue === "" ? adhaarCard : adhaarCard}
                  name="adhaar"
                  onChange={handleMaxLength}
                  placeholder="Your Aadhaar Number"
                  maxLength={12}
                />
              </label>
              <label className="single-input-wrap">
                <img
                  src={`data:image/png;base64,${sessionDataCapctca}`}
                  alt="capcta"
                />
                <input
                  type="text"
                  name="capcta"
                  placeholder="Fill Capcta"
                  className="mt-2"
                />
              </label>
              <button
                className="btn btn-link btn-sm text-dark"
                type="button"
                onClick={handleRefreshCapcta}
              >
              <div className="d-flex align-items-center">
                Reload Capcta <FiRefreshCcw className=" ml-3" />
              </div>
              </button>
              {loading ? (
                <button type="submit" className="btn btn-purple" disabled>
                  Sending OTP...
                </button>
              ) : (
                <button type="submit" className="btn btn-purple">
                  Send OTP
                </button>
              )}
            </form>
          </div>
        </div>
      )}
    </Section>
  );
};

export default SignUpAdhaar;
