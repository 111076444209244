import React from "react";

const TermLegal = () => {
  return (
    <div className="accordion-body">
      <p>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          These <strong>terms and conditions</strong> of use (“Terms”) along
          with privacy policy (“Privacy Policy”) forms a legally binding
          agreement (“Agreement”) between You and us (
        </span>
        <span
          style={{
            color: "rgb(209,72,65)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          ludogamer.com
        </span>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          ).
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          Hence, We insist that You read these Terms and Privacy Policy and let
          Us know if You have any questions regarding the same. We will try Our
          best to answer Your queries.
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          <strong>A. USERS’ APPROVAL</strong>
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          1. Users approve of and accept over Agreement by:
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          (a) reading all terms and condition
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          (b) reading all rules of this app
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          2. Users may accept this Agreement only if:
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          (a) Such User is a natural person, is of the legal age (18 years or
          older), eligibility and mental capacity to form a binding contract
          with us.
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          (b) Such User is a not a resident of Tamil Nadu, Andhra Pradesh,
          Telangana, Assam, Orissa, Sikkim, Nagaland.
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          (c) Such User is a juristic person, is lawfully existing, and has all
          the authorizations, permits, and allowances to enter into this
          Agreement and form a binding contract.
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          (d) Such User is not legally barred or restricted from using the App.
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          3. You understand that We want You to not use the App if You do not
          understand, approve of or accept all the terms specified in this
          Agreement. Hence, You are requested to read these Terms and Privacy
          Policy carefully and understand the Agreement before You accept it and
          agree to be bound by it.
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          4. The Agreement shall govern the relationship of each User with us.
          However, We may also execute separate written agreements with its
          Users. In case of conflict between terms of such separate written
          agreement and this Agreement, the terms of the separate written
          agreement shall prevail.
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          <strong>B. PROVISION OF THE APP</strong>
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          1. Section 12 of the Public Gambling Act, 1867 provides that games of
          mere skill are exempt from the application of the Act. The Supreme
          Court of India and various High Courts in India have opined that a
          game of mere skill is a game “in which, although the element of chance
          necessarily cannot be entirely eliminated, success depends principally
          upon the superior knowledge, training, attention, experience and
          adroitness of the player. A game of skill is one in which the element
          of skill predominates over the element of chance.” No penalty can be
          imposed upon a person for playing such games of skill.
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          2. Users must note that ‘Ludo’ game available for challenge in our
          platform is ‘Games of Skill’, under Indian law, and that we does not
          support, endorse or offer to Users ‘games of chance’ for money. While
          ‘Games of Skill’ do not have a comprehensive definition, they are
          those games where the impact of a player’s effort and skill on the
          outcome of a game is higher than the impact of luck and chance.
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          3. It may be noted that States are permitted, by the Indian
          Constitution, to enact laws regulating betting and gambling in their
          respective jurisdictions. In furtherance of these powers, various
          States have enacted anti- gambling legislations. Such legislations are
          largely in concert with the Public Gambling Act of 1867 (and include
          the exception of “games of skill”). Where a State legislation on
          gambling exists, it prevails over the Public Gambling Act of 1867. In
          this regard, the Assam Game and Betting Act, 1970 and Orissa
          (Prevention of) Gambling Act, 1955 and Telangana State Gaming
          (Amendment) Ordinance and High Court Judgment in Gujarat, 2017
          prohibits games with money stakes and also does not create an
          exception for games of skill. Therefore, currently, residents of
          Assam, Odisha, Telangana and Gujarat are not permitted to play on our
          site.
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          4. The games rules are clearly defined on this platform and Users are
          encouraged to read, understand and follow these rules to be successful
          in these games.
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          5. The games on our platform are ‘Games of Skills’, such that the
          outcome / success in the games is directly dependent on the User’s
          effort, performance and skill. By choosing how to play, the actions of
          Users shall have direct impact on the game.
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          6. Every game will have some elements of chance, but in the form of
          challenges / obstacles that a User would be able to overcome using
          his/her skills and knowledge of the game. Elements of luck are present
          in every game to add thrill and excitement, but no two attempts at a
          game are identical so Users must use their skills in order to be
          successful
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          7. Since the games available on our platform can be won through Users’
          skills and such skills may be enhanced with practice and experience,
          the performance of a User may improve with time and practice.
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          8. Certain games may have pre-determined outcomes (Ludo), and these
          outcomes are achievable by Users using their skills.
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          <strong>C. GAME RULES</strong>
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          1. Player who sets a challenge will share a room id/room code with
          his/her opponent.
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          2. On winning both players have to update there results in following
          manner:
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          (a) if you have won, select ‘I Won’ option and upload winning
          screenshot of the game.
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          (b) if you have lost, select ‘I Lost’ option.
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          (c) if match is not started and both parties doesn't want to play,
          select ‘Cancel’ option.
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          3. User must have to record every game, and if any player is hacking
          or cheating a game, contact support.
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          4. If game is not started, if you haven't played a single move
          yourself, please show the recording of game to support. Game will be
          cancelled only if you have recording.
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          5. If you don't have any proof against player cheating and error in
          game, you will be considered as lost.
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          6. If you have not moved a single pawn or no pawn is open at home,
          your game will be cancelled.
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          7. If your opponent leaves match purposely in starting or initial
          game, and opponent doesn't have any valid proof for cancellation, you
          will be awarded win.
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          <strong>D. DEPOSIT / WITHDRAWAL</strong>
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          1. Players can deposit their balance in{" "}
        </span>
        <a href="https://ludogamer.com/#/buy" target="_self">
          <span
            style={{
              color: "rgb(33,37,41)",
              backgroundColor: "rgb(255,255,255)",
              fontSize: 16,
            }}
          >
            <strong>Buy Coins</strong>
          </span>
        </a>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          {" "}
          section.{" "}
        </span>
      </p>
      <p>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          Important:- If we detect any type of suspecious transaction/activity
          in your account, in such cases we have rights to Block your account
          temporarily. Kindly contact Admins in such cases and provided the
          needed details to Un-block your account.{" "}
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          2. Player can take withdrawal by setting a Sell Request on your app.
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          3. Deposit and withdrawal requests are completed by support at any
          time.
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          4. Any wrong payment detail given by you, will not be considered in
          refund.
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          5. Once a withdrawal is done, you don't have any authority to raise
          any query.
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          6. If withdrawal request go on pending, user must have to wait for 1-5
          days.
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          <strong>E. PENALITY FOR WRONG UPDATES</strong>
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          1. If you put the wrong update on any match, you will be charged
          penality of:
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          (a) if your game is below 1000, penalty will be 10%.
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          (b) if your game is above 1000 and below 5000, penality will be 50
          flat.
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          (c) if your game is above 5000 and below 15000, penality will be 100
          flat.
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          2. If you don't update result after losing, you will be charged
          penality of 25 flat.
        </span>
      </p>
      <p>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          3. Players can have only single account in case multiple accounts
          found, We have authority to ban those account permanently &amp; add
          penalty
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          <strong>F. COMMISSION CHARGES</strong>
        </span>
      </p>
      <p style={{ textAlign: "left" }}>
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          1. Net 3
        </span>
        <strong>% </strong>commission
        <span
          style={{
            color: "rgb(33,37,41)",
            backgroundColor: "rgb(255,255,255)",
            fontSize: 16,
          }}
        >
          {" "}
          (after referral money deduction) will be charged on challenge amount.
        </span>
      </p>
    </div>
  );
};

export default TermLegal;
