import React, { useState } from "react";
import Section from "./layouts/Section";
import { useCookies } from "react-cookie";
import url from "../environemt";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
const Signin = () => {
  localStorage.clear();
  const navigate = useHistory();
  const [cookies, setCookie] = useCookies(["ludoT"]);
  const [error, setError] = useState([]);
  const [phonevalue, setPhoneValue] = useState("");
  const [loading, setLoading] = useState(false);
  
  try {
    if (cookies.sg2 === 1) {
      navigate.push("/games-play/clm");
    }
  } catch (err) {
    // console.log(err);
  }
  const handleLoginsubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const data = {
      phone_no: e.target[0].value,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(`${url}login`, requestOptions);
      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.data[0]);
      }
      let actualData = await response.json();
      setCookie("ludoT", actualData.data.token);
      setCookie("sg1", "1");
      localStorage.setItem("phoneNo", JSON.stringify(data));
      toast.success("OTP Sent Successfully");
      navigate.push("/otplogin");
    } catch (err) {
      setError(err.message);
      // console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const handleMaxLength = (e) => {
    if (e.target.value.length > e.target.maxLength) {
      setPhoneValue(e.target.value.slice(0, e.target.maxLength));
    } else {
      setPhoneValue(e.target.value);
    }
  };
  return (
    <Section allNotification={false} searchPopup={true} title={"Signin"}>
      <div className="ba-page-name text-center mg-bottom-40">
        <h3>Login</h3>
      </div>
      <div className="signin-area mg-bottom-35">
        <div className="container">
          <form className="contact-form-inner" onSubmit={handleLoginsubmit}>
            <label className="single-input-wrap">
              <span>
                Mobile No.
                <span style={{ color: "red", display: "inline" }}>*</span>
              </span>
              <input
                type="number"
                placeholder="Mobile No."
                required
                maxLength={10}
                value={phonevalue}
                onChange={handleMaxLength}
              />
            </label>
            {loading ? (
              <button className="btn btn-black" type="submit" disabled>
                Sending OTP..
              </button>
            ) : (
              <button className="btn btn-black" type="submit">
                Send OTP
              </button>
            )}
            <div
              className="d-flex align-items-end  mt-3"
              style={{ gap: "15px" }}
            >
              <p className="mt-2" style={{ fontSize: "0.75rem" }}>
                <br />
                Don't Have An Account?{" "}
              </p>
              <Link className="btn btn-red mt-0" to="/signup">
                Register
              </Link>
            </div>
          </form>
        </div>
      </div>
    </Section>
  );
};

export default Signin;
