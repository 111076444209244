import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Section from "./layouts/Section";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import url from "../environemt";
import Popup from "reactjs-popup";
import { useSelector } from "react-redux";
import RoomCodeWon from "./RoomCodeWon";
import RoomCodelost from "./RoomCodelost";
import imageCompression from "browser-image-compression";
const Roomcode = () => {
  const user = useSelector((state) => state.user.userData);
  const openRoomTrue = useSelector((state) => state.user.roomUpdate);
  const params = useParams();
  // eslint-disable-next-line
  const navigate = useHistory();
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  const [updateLoading, setUpdateLoading] = useState(false);
  const fee = localStorage.getItem("f");
  const handleCopy = () => {
    navigator.clipboard.writeText(
      room.room_code ? room.room_code : openroom.room_code
    );
    toast("Copied", {
      position: "top-right",
      autoClose: 500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };
  // eslint-disable-next-line
  const [cookies] = useCookies();
  const [room, setRoom] = useState([]);
  const [names, setNames] = useState({ name: "Searching..." });
  const [namesSecond, setNamesSecond] = useState("Searching...");
  const [error, setError] = useState([]);
  const token = cookies.ludoM;
  const openroom = useSelector((state) => state.user.openRoom);
  useEffect(() => {
    if (!JSON.parse(localStorage.getItem("ro"))) {
      // console.log("No room code");
    } else {
      setRoom({
        room_code: JSON.parse(localStorage.getItem("ro")),
      });
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const requestOptionsSecond = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const getById = async (id1, id2) => {
      try {
        if (id1 !== null) {
          const response = await fetch(
            `${url}users/${id1}`,
            requestOptionsSecond
          );
          let actualData = await response.json();
          setNames(actualData.data);
        }
        if (id2 !== null) {
          const responseSecond = await fetch(
            `${url}users/${id2}`,
            requestOptionsSecond
          );
          let actualDataSecond = await responseSecond.json();
          setNamesSecond(actualDataSecond.data);
          localStorage.clear();
        }
        setError(null);
      } catch (err) {
        setError(err.message);
        // console.log(error);
      } finally {
        setLoading(false);
      }
    };
    const getName = async () => {
      const id = localStorage.getItem("ri");
      // const id = ids === null ? "000000" : ids; // Fixed the ternary operator  ROOM CLOSED
      if (id === null) {
        if (openroom) {
          localStorage.setItem("ri", openroom.id);
          localStorage.setItem("f", openroom.contest_fee);
        }
        // navigate.push("/games");
        // toast.success("This Room Code is Closed, please select another room ");
      } else {
        try {
          const response = await fetch(
            `${url}game_rooms/${id}`,
            requestOptionsSecond
          );
          if (!response.ok) {
          }
          let actualData = await response.json();
          if (actualData.data.user_i !== null) {
            getById(actualData.data.user_i, actualData.data.user_2);
          }
          localStorage.setItem("u1", actualData.data.user_i);
          localStorage.setItem("u2", actualData.data.user_2);
          setError(null);
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      }
    };
    getName();
    const interval = setInterval(() => {
      const u1 = localStorage.getItem("u1");
      const u2 = localStorage.getItem("u2");
      const id = localStorage.getItem("ri");
      if (u1 === "null" || u2 === "null" || id !== null) {
        getName();
      } else {
        // console.log("second user in room code");
        clearInterval(interval);
      }
    }, 5000);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [openRoomTrue]);

  const handleCancel = async (e, result) => {
    setUpdateLoading(true);
    e.preventDefault();
    const data = new FormData();
    data.append("result", result);
    data.append(
      "room_code",
      room.room_code ? room.room_code : openroom.room_code
    );
    data.append("challenge_id", params.by);
    const requestOptions = {
      method: "POST",
      body: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await fetch(`${url}update_result`, requestOptions);
      if (!response.ok) {
        throw new Error(
          `This is an HTTP error: The status is ${response.status}`
        );
      }
      let actualData = await response.json();
      if (actualData.success) {
        navigate.push("/");
        toast.success(
          "Result Updated Successfully. Please Provide 3-5 Minutes To Update the Result "
        );
        localStorage.clear();
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setUpdateLoading(false);
    }
  };
  const handleUpdate = async (e, result) => {
    setUpdateLoading(true);
    e.preventDefault();
    const data = new FormData();
    data.append("result", result);
    if (result === "win") {
      // image comppressor
      const imageFile = e.target[0].files[0];
      // console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: imageFile.size / 1024 / 1024 >= 0.9 ? 800 : 1800,
        useWebWorker: true,
        alwaysKeepResolution: true,
        maxIteration: 5,
        initialQuality: 1,
      };
      var compressedFile = await imageCompression(imageFile, options);
      if (imageFile.size / 1024 / 1024 <= 0.3) {
        compressedFile = imageFile;
      }

      data.append("screenshot", compressedFile);
    }
    data.append(
      "room_code",
      room.room_code ? room.room_code : openroom.room_code
    );
    data.append("challenge_id", params.by);

    const requestOptions = {
      method: "POST",
      body: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await fetch(`${url}update_result`, requestOptions);
      if (!response.ok) {
        throw new Error(
          `This is an HTTP error: The status is ${response.status}`
        );
      }
      let actualData = await response.json();
      if (actualData.success) {
        toast.success(
          "Result Updated Successfully. Please Provide 3-5 Minutes To Update the Result "
        );
        navigate.push("/");
        localStorage.clear();
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setUpdateLoading(false);
    }
  };
  // if(!back){
  //     window.history.pushState(null, document.title, window.location.href);
  //     window.addEventListener('popstate', function (event) {
  //         if (!back) {
  //             window.history.pushState(null, document.title, window.location.href);
  //             toast.error("Update Room Status")
  //         }else if(back){
  //             navigate.push('/games')
  //         }
  //     })
  // }
  // eslint-disable-next-line

  useEffect(() => {
    const id = localStorage.getItem("ri");
    if (id) {
      localStorage.setItem("ri", openroom.id);
      localStorage.setItem("f", openroom.contest_fee);
    }
    // eslint-disable-next-line
  }, [openRoomTrue]);
  return (
    <Section page={"roomcode"}>
      {loading ? (
        <div className="m-auto w-100 d-flex justify-content-center align-items-center h-60vh">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <br />
          <br />
          <div className="shadow border m-3 mx-4 pt-4 bg-white mt-5">
            <div className="d-flex vs-container">
              <div className="col-sm text-center d-flex flex-column align-items-center">
                <img
                  src={process.env.PUBLIC_URL + "/assets/img/avtar.png"}
                  alt="avtar"
                />
                <p>{names.name !== "Searching..." ? names.name : user.name}</p>
              </div>
              <div className="col-sm text-center d-flex flex-column align-items-center justify-content-center">
                <img
                  className="vss"
                  src={process.env.PUBLIC_URL + "/assets/img/vs_icon.png"}
                  alt="="
                />
                <p
                  className="text-success d-flex align-items-center justify-content-center"
                  style={{ gap: "7px" }}
                >
                  <img
                    src={process.env.PUBLIC_URL + "/assets/img/coin.png"}
                    alt="img"
                    className="w-100"
                  />{" "}
                  {fee}
                </p>
              </div>
              <div className="col-sm text-center  d-flex flex-column align-items-center">
                <img
                  src={process.env.PUBLIC_URL + "/assets/img/avtar.png"}
                  alt="avtar"
                />
                <p>
                  {namesSecond !== "Searching..."
                    ? namesSecond.name
                    : "Searching..."}
                </p>
              </div>
            </div>
          </div>
          {/* waring danger */}
          <div className="shadow border m-3 mx-4 pt-4 bg-white">
            <div className="d-flex vs-container">
              <p className="px-2 text-danger text-center">
                Time to play the game! Admin will
                <br /> decide the final game result.
                <br />
                अपना खेल शुरू करिये | खेल का अंतिम और फाइनल फैसला एडमिन का रहेगा
                |
              </p>
            </div>
          </div>
          {/* room code section */}
          <div className="shadow border bg-white m-3 mx-4 btn-height">
            <div className="d-flex flex-column mb-4 justify-content-center align-items-center bg-grey">
              <span className="p-2">Room Code </span>
            </div>
            <div className="text-center">
              <h1 className="heading-room">
                {room.room_code ? room.room_code : openroom.room_code}
              </h1>
            </div>
            <div className="d-flex flex-column">
              <button
                className="btn btn-basic btn-sm py-0 rounded m-3"
                onClick={handleCopy}
              >
                <i className="fa fa-clipboard mx-1"></i>Copy Code
              </button>
            </div>
          </div>
          {/* game result */}
          <div className="shadow border bg-white m-3 mx-4 btn-height">
            <div className="d-flex flex-column mb-4 justify-content-center align-items-center bg-grey">
              <span className="p-2">Game Result </span>
            </div>
            <div className="text-center">
              <p className="p-3">
                After completion of your game, select the result and post your
                screenshot respectively
              </p>
            </div>
            <div className="d-flex flex-column flex-wrap">
              <Popup
                className="top-50"
                trigger={
                  <button className="btn btn-green btn-sm py-0 rounded m-3">
                    I Won
                  </button>
                }
                modal
              >
                {(close) => (
                  <RoomCodeWon
                    handleUpdate={handleUpdate}
                    close={close}
                    updateLoading={updateLoading}
                  />
                )}
              </Popup>
              <Popup
                trigger={
                  <button className="btn btn-danger btn-sm py-0 rounded mx-3 mb-3">
                    I Lost
                  </button>
                }
                modal
              >
                {(close) => (
                  <RoomCodelost
                    handleUpdate={handleUpdate}
                    close={close}
                    updateLoading={updateLoading}
                  />
                )}
              </Popup>

              <Popup
                trigger={
                  <button className="btn btn-outline-grey btn-sm py-0 rounded mx-3 mb-3">
                    Cancel
                  </button>
                }
                modal
              >
                {(close) => (
                  <div>
                    <h1 className="text-center fs-5">Cancel Game</h1>
                    <div>
                      <form
                        className="d-flex flex-column"
                        onSubmit={(e) => handleCancel(e, "cancel")}
                      >
                        {updateLoading ? (
                          <button
                            type="submit"
                            className="btn btn-outline-success btn-sm py-0 rounded mx-3  text-dark border border-success border-2 mt-3 mb-1"
                            disabled
                          >
                            <i className="fa fa-spinner fa-spin p-0 ml-2"></i>
                            Canceling Room....
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-success btn-sm py-0 rounded mx-3  text-white border border-success border-2 mt-3 mb-1"
                          >
                            Cancel Room
                          </button>
                        )}
                        <button
                          type="button"
                          className="btn btn-grey btn-sm py-0 rounded mx-3 mb-3 mt-0"
                          onClick={() => close()}
                        >
                          Back
                        </button>
                      </form>
                    </div>
                  </div>
                )}
              </Popup>
            </div>
          </div>
          {/* peanlty */}
          <div className="shadow border bg-white m-3 mx-4 btn-height">
            <div className="d-flex flex-column mb-4 justify-content-center align-items-center bg-grey">
              <span className="p-2">Penalty</span>
            </div>
            <div className="container p-3">
              <table className="border table-roomcode">
                <thead className="border">
                  <tr>
                    <td>Amount</td>
                    <td>Reason</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> 100</td>
                    <td>Fraud/Fake Screenshot</td>
                  </tr>
                  <tr>
                    <td>50</td>
                    <td>Wrong Update</td>
                  </tr>
                  <tr>
                    <td>50</td>
                    <td>No Update</td>
                  </tr>
                  <tr>
                    <td>25</td>
                    <td>Abusing</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </Section>
  );
};

export default Roomcode;
