import React from "react";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";
const style = { fontSize: "1.2rem" };
const Navbar = () => {
  // eslint-disable-next-line
  const [cookies, setCookie, removeToken] = useCookies(["ludoM"]);
  // const wallet = useSelector((state) => state.user.userWallet)
  const userData = useSelector((state) => state.user.userData);
  const logout = () => {
    removeToken("ludoM", { path: "/" });
    removeToken("ludoT", { path: "/" });
    removeToken("sg2", { path: "/" });
    removeToken("sg1", { path: "/" });
    localStorage.removeItem("acno");
    localStorage.removeItem("lastWithdrawCall");
    return <Navigate to="/" />;
  };
  return (
    <div className="ba-navbar">
      <div className="ba-navbar-user">
        <div className="menu-close">
          <i className="la la-times" />
        </div>
        <div className="thumb">
          <img
            style={{ width: "50px" }}
            src={process.env.PUBLIC_URL + "/assets/img/user.png"}
            alt="user"
          />
        </div>
        <div className="details">
          <h5> {userData.name && userData.name} </h5>
          <p>ID: {userData.id && userData.id}</p>
        </div>
      </div>
      {/* <div className="ba-add-balance-title ba-add-balance-btn">
                <h5>Add Balance</h5>
                <p> {wallet.sumin&& wallet.sumin-wallet.sumout}</p>
            </div> */}
      {/* <div className="ba-add-balance-title style-two ba-add-balance-btn">
                <h5>Deposit</h5>
                <i className="fa fa-plus" />
            </div> */}
      <div className="ba-main-menu">
        <h5>Menu</h5>
        <ul>
          <li>
            <Link to={"/transaction-history"} style={style}>
              Transaction History
            </Link>
          </li>
          <li>
            <Link to={"/user-setting"} style={style}>
              Profile
            </Link>
          </li>
          <li>
            <Link to={"/wallet"} style={style}>
              Wallet
            </Link>
          </li>
          <li>
            <Link to={"/about"} style={style}>
              About Us
            </Link>
          </li>
          <li>
            <Link to={"/term-legal"} style={style}>
              Terms & Conditions
            </Link>
          </li>
          <li>
            <Link to={"/support"} style={style}>
              Support
            </Link>
          </li>
          <li>
            <Link to={"/referal-earn"} style={style}>
              Refer & Earn
            </Link>
          </li>
          <li>
            <Link to={"/spin-wheel"} style={style}>
              Spin Wheel
            </Link>
          </li>

          {/* <li><Link to={'/signin'} onClick={logout}>Log Out</Link></li> */}
        </ul>
        <Link className="btn btn-purple" to={"/signin"} onClick={logout}>
          Logout
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
