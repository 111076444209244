import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <>
        <div className="footer-area bg-white">
          <div className="container">
            <div
              style={{
                position: "fixed",
                width: "100%",
                bottom: "0%",
              }}
              className="footer-bottom text-center"
            >
              <p className="text-dark">
                Copyright © Ludo Gamers 2023. All Rights Reserved.{" "}
              </p>
            </div>
          </div>
        </div>

        {/* Back to Top Area */}
        <div className="back-to-top">
          <span className="back-top">
            <i className="fa fa-angle-up" />
          </span>
        </div>
      </>
    );
  }
}

export default Footer;
