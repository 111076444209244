import React, { useRef, useState } from "react";
import Footer from "./layouts/Footer";
import Navbar from "./layouts/Navbar";
import { Link, useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import Private from "../privateRoute/Private";
import url from "../environemt";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

const Home = () => {
  // eslint-disable-next-line
  const [cookies] = useCookies();
  const userData = useSelector((state) => state.user.userData);
  const sum = useSelector((state) => state.user.userWallet);
  const withdraw = useSelector((state) => state.user.userWalletWith);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useHistory();
  const closeRef = useRef();
  // redirect to games page
  try {
    if (cookies.sg2 !== 1) {
      navigate.push("/");
    } else {
      return <Redirect to="/games-play/clm" />
    }
  } catch (err) {
    // console.log(err);
  }
  const token = cookies.ludoM;
  const initialPayment = async (e) => {
    e.preventDefault();
    const data = {
      amount: e.target[0].value,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(`${url}intialPayment`, requestOptions);
      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.message);
      }
      let actualData = await response.json();
      window.open(actualData.message, "_blank");
      setError(null);
    } catch (err) {
      setError(err.message);
      // console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const initialWithdraw = async (e) => {
    e.preventDefault();
    if (e.target[0].value !== e.target[1].value) {
      toast.error("Please Enter The Same UPI Id ");
    } else {
      if (withdraw.sumin - withdraw.sumout < e.target[2].value) {
        if (withdraw.sumin - withdraw.sumout === 0) {
          toast.error("You Don't have enough Coins to withdraw");
        } else {
          toast.error(`Please Enter Below ${withdraw.sumin - withdraw.sumout}`);
        }
      } else {
        setLoading(true);
        const data = {
          upi_id: e.target[0].value,
          coins: e.target[2].value,
        };
        const requestOptions = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        };
        try {
          const response = await fetch(
            `${url}intial_upi_payout`,
            requestOptions
          );
          if (!response.ok) {
            const errorData = await response.json();
            // console.log(errorData);
            toast.error(errorData.message);
          }
          let actualData = await response.json();
          setLoading(false);
          if (actualData.success) {
            toast(actualData.message);
            closeRef.current?.click();
          }
          setError(null);
        } catch (err) {
          setError(err.message);
          // console.log(error);
        } finally {
          setLoading(false);
        }
      }
    }
  };
  //localStorage.clear();

  return (
    <Private>
      {/* Navigation Bar Imported from Layouts */}
      <Navbar />
      {/* Auto Notification Imported from Layout */}
      {/* Header Starts */}
      <div
        className="header-area header-gradient"
        style={{ backgroundImage: "url(assets/img/bg/1.png)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-4 col-4">
              <div className="menu-bar">
                <i className="fa fa-bars" />
              </div>
            </div>
            <div className="col-sm-4 col-4 text-center logo-img">
              <Link to={"/"} className="logo ">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/img/ludo-icon-small.png"
                  }
                  alt="logo"
                  className="logo-img"
                />
              </Link>
            </div>
            <div className="col-sm-4 col-4 text-right">
              <ul className="header-right">
                <li>
                  <Link className="header-user" to={"/user-setting"}>
                    <img
                      src={process.env.PUBLIC_URL + "/assets/img/user.png"}
                      alt="img"
                      className="icon-img"
                      style={{ width: "58.2%" }}
                    />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Header Ends */}
      <div className="add-balance-inner-wrap">
        <div className="container">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add Coins</h5>
                <button
                  id="aclose"
                  type="button"
                  className="close"
                  data-dismiss="modal"
                >
                  ×
                </button>
              </div>

              <div className="modal-body">
                <div className="action-sheet-content">
                  <form onSubmit={initialPayment}>
                    <div
                      style={{ margin: "15px" }}
                      className="alert alert-danger"
                      role="alert"
                    >
                      <strong>
                        <i className="fa fa-exclamation-triangle"></i>{" "}
                      </strong>{" "}
                      यदि आप किसी भी पेमेंट का बैंक से Complain डालते है तो आपके
                      खाते को Block कर दिया जायेगा ! इसलिए किसी और से अपने Ludo
                      ID में पैसे न डलवाये ! और यदि आप खुद जान भूझकर बैंक से
                      रिफंड लेने के लिए Complain डालते है तो आपकी Ludo ID
                      पूर्णतः बंद कर दी जाएगी !
                    </div>
                    <div className="text-center">
                      <h5 className="title inline text-center">Total Coins</h5>
                      <h5 className="amount inline">
                        {sum.sumin && (sum.sumin - sum.sumout).toFixed(2)}
                      </h5>
                    </div>
                    <div className="form-group basic">
                      {/* <label className="label">Enter Coin Amount</label> */}
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="input1">
                            <img
                              style={{ height: "30px" }}
                              src={
                                process.env.PUBLIC_URL + "/assets/img/coin.png"
                              }
                              alt="img"
                            />
                          </span>
                        </div>
                        <input
                          type="number"
                          className="form-control form-control-lg"
                          placeholder="Enter Coins"
                          required
                        />
                      </div>
                    </div>
                    <div className="text-center">
                      <p
                        style={{ fontSize: "10px" }}
                        className="title inline text-center"
                      >
                        By continuing, you agree to our{" "}
                        <Link to="term-legal"> Terms & Conditions </Link>
                      </p>
                    </div>
                    <div className="form-group basic">
                      <button
                        type="submit"
                        className="btn-c btn-black btn-block btn-lg"
                        style={{ cursor: "pointer" }}
                      >
                        Add Coins
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="add-balance-inner-wrap2">
        <div className="container">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Withdraw Coins</h5>
                <button
                  id="wclose"
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  ref={closeRef}
                >
                  ×
                </button>
              </div>
              <div className="modal-body">
                <div className="action-sheet-content">
                  <form onSubmit={initialWithdraw}>
                    <div
                      className="ba-cart-inner mb-3"
                      style={{ backgroundImage: "url('assets/img/bg/4.png')" }}
                    >
                      <p
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                      >
                        Withdrawl Limit
                      </p>
                      <span className="inline">
                        <h5>100</h5>
                        <p>Minimum</p>
                      </span>
                      <span className="inliner">
                        <h5>1,00,000</h5>
                        <p>Maximum</p>
                      </span>
                    </div>
                    <div className="text-center">
                      <h5 className="title inline text-center">
                        Available Coins:
                      </h5>
                      <h5 className="amount inline">
                        {withdraw.sumin &&
                          (withdraw.sumin - withdraw.sumout).toFixed(2)}
                      </h5>
                    </div>
                    <div className="form-group basic">
                      {/* <label className="label">Enter UPI ID</label> */}
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="UPI Id"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group basic">
                      {/* <label className="label">Confirm UPI ID</label> */}
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Confirm UPI Id"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group basic">
                      {/* <label className="label">Enter Coin Amount</label> */}
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="input1">
                            <img
                              style={{ height: "30px" }}
                              src={
                                process.env.PUBLIC_URL + "/assets/img/coin.png"
                              }
                              alt="img"
                            />
                          </span>
                        </div>
                        <input
                          type="number"
                          className="form-control form-control-lg"
                          placeholder="Enter Coins"
                          required
                        />
                      </div>
                    </div>
                    <div className="text-center">
                      <p
                        style={{ fontSize: "10px" }}
                        className="title inline text-center"
                      >
                        By continuing, you agree to our{" "}
                        <Link to="term-legal"> Terms & Conditions </Link>
                      </p>
                    </div>
                    <div className="form-group basic">
                      {loading ? (
                        <button
                          type="submit"
                          className="btn-c btn-black btn-block btn-lg"
                          disabled
                        >
                          Withdrawing....
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn-c btn-black btn-block btn-lg"
                        >
                          Withdraw
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Navbar Ends */}
      {/* balance start */}
      <div className="balance-area pd-top-40 mg-top-50">
        <div className="container">
          <div className="balance-area-bg balance-area-bg-home">
            <div className="balance-title text-center">
              <h6>
                Welcome,{" "}
                <span style={{ color: "#ed1e1f" }}>
                  Dear {userData.name && userData.name}{" "}
                </span>{" "}
              </h6>
            </div>
            <div className="ba-balance-inner text-center d-flex align-items-center justify-content-center bg-white">
              <div className="icon mb-0">
                <img
                  src={process.env.PUBLIC_URL + "/assets/img/coin.png"}
                  alt="img"
                />
              </div>
              <h5 className="title inline mb-0">Total Coins</h5>
              <h5 className="amount inline">
                {sum.sumin && (sum.sumin - sum.sumout).toFixed(2)}
              </h5>
            </div>
          </div>
        </div>
      </div>
      {/* balance End */}
      {/* add balance start */}
      <div className="add-balance-area">
        <div className="container">
          <div className="ba-add-balance-title ba-add-balance-btn">
            <h5>Add Coins</h5>

            <img
              className="coins"
              src={process.env.PUBLIC_URL + "/assets/img/coin.png"}
              alt="img"
            />
          </div>
          <br />
          <div className="ba-add-balance-title ba-add-balance-btn2">
            <h5>Withdraw Coins</h5>
            <img
              className="coins"
              src={process.env.PUBLIC_URL + "/assets/img/coin.png"}
              alt="img"
            />
          </div>
          <div className="ba-add-balance-inner">
            <div className="row custom-gutters-20">
              <div className="col-6">
                <Link
                  className="btn btn-white d-flex justify-content-center"
                  to={"/games-play/clm"}
                >
                  Play Ludo
                  <img
                    className="dice"
                    src={process.env.PUBLIC_URL + "/assets/img/dice.png"}
                    alt="img"
                  />
                </Link>
              </div>
              <div className="col-6">
                <Link
                  className="btn btn-white d-flex justify-content-center text-white"
                  to={"/referal-earn"}
                  style={{ backgroundColor: "#31b545" }}
                >
                  Share Link
                  <img
                    className="dice"
                    src={process.env.PUBLIC_URL + "/assets/img/whatsapp.webp"}
                    alt="img"
                  />
                </Link>
              </div>

              <div className="col-6">
                <Link
                  className="btn btn-white d-flex justify-content-center"
                  to={"/transaction-history"}
                >
                  Game History
                  <img
                    className="dice"
                    src={
                      process.env.PUBLIC_URL + "/assets/img/game_history.png"
                    }
                    alt="img"
                  />
                </Link>
              </div>

              <div className="col-6">
                <Link
                  className="btn btn-white d-flex justify-content-center text-white"
                  to={"/support"}
                  style={{ backgroundColor: "#31b545" }}
                >
                  WhatsApp Support
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ margin: "15px" }}
          className="alert alert-danger"
          role="alert"
        >
          <strong>
            <i className="fa fa-exclamation-triangle"></i>{" "}
          </strong>{" "}
          OTP किसी को ना दे ॥ हमारी टीम किसी से किसी भी काम के लिये OTP नहीं
          लेती है । यदि आप किसी को OTP देते है तो उसके ज़िम्मेदार आप स्वयं होंगे
          🙏
        </div>
      </div>
      {/* add balance End */}
      {/* Footer Component Imported from Layouts */}
      <Footer />
    </Private>
  );
};

export default Home;
