import React from "react";
import Section from "./layouts/Section";

const Support = () => {
  return (
    <Section>
      <div className=" col-12 mx-auto p-3 g-0" style={{ marginTop: "70px" }}>
        <div className="d-flex flex-column align-items-stretch justify-content-start">
          <img
            src={process.env.PUBLIC_URL + "/assets/img/Support.jpg"}
            alt="support"
            className="w-75 mx-auto"
          />
          {/* <div className="d-flex flex-column align-items-stretch">
            <a className="text-decoration-none text-white w-100" href="#y">
              <button className="btn btn-primary btn-lg mb-3 d-flex align-items-center justify-content-center w-100">
                <div className="hstack gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                  >
                    <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793V2zm5 4a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                  </svg>
                  <span className="text-capitalize">live chat</span>
                </div>
              </button>
            </a>
          </div> */}
          <div className="d-flex flex-column align-items-stretch mb-3">
            <a
              href={`https://wa.me/919138279290%20?text=Hi%20Team%20Ludo%20Gamers,%20I%20need%20Support%20with%20the%20Room%20Code.`}
              className="text-decoration-none text-white w-100 "
              target="blank"
            >
              <button
                className="btn btn-lg text-white  btn-secondary d-flex align-items-center justify-content-center w-100"
                style={{
                  background: "#1dcc39",
                }}
              >
                <div className="hstack gap-2">
                  <svg
                    style={{ margin: "auto", marginRight: "5px" }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    className="me-2"
                  >
                    <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                  </svg>
                  <span className="text-capitalize">Connect on WhatsApp</span>
                </div>
              </button>
            </a>
          </div>
          <div className="d-flex flex-column align-items-stretch">
            <a
              href="mailto:support@ludogamers.com"
              className="text-decoration-none text-white w-100"
              target="blank"
            >
              <button className="btn btn-primary btn-lg d-flex align-items-center justify-content-center w-100">
                <div className="hstack gap-2">
                  <svg
                    style={{ margin: "auto", marginRight: "5px" }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                  >
                    <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                  </svg>
                  <span className="text-capitalize text-white">
                    Reach Us Via Email
                  </span>
                </div>
              </button>
            </a>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Support;
