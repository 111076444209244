import React, { Fragment, Suspense, lazy, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import url from "../environemt";
import Section from "./layouts/Section";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { GameListVs } from "./GameListRichVs";
import { motion } from "framer-motion";
import Popup from "reactjs-popup";
import { addRoomCode } from "../store/slicer";

const AutoManual = lazy(() => import("./AutoManual"));
const GamePlayDirect = () => {
  const dispatch = useDispatch();
  const [gameList, setGameList] = useState([]);
  // eslint-disable-next-line
  const [vsDisplay, setVsDisplay] = useState([]);
  const [priceValue, setPriceValue] = useState("");
  // eslint-disable-next-line
  const [cookies] = useCookies();
  // eslint-disable-next-line
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingSecond, setLoadingSecond] = useState(false);
  const navigate = useHistory();
  const token = cookies.ludoM;
  const walletMoney = useSelector((state) => state.user.userWallet);

  const param = useParams();

  const openroom = useSelector((state) => state.user.openRoom);
  const user = useSelector((state) => state.user.userData);
  const handleClick = (id, by, mode, codeBy, manauto) => {
    if (
      walletMoney.sumin - walletMoney.sumout >= id &&
      openroom[0] === "room closed"
    ) {
      if (by === 0) {
        navigate.push(`/room-code/${id}/${param.mode}`);
      } else {
        if (codeBy === user.name) {
          setLoading(true);
          localStorage.setItem("f", id);
          navigate.push(`/roomcode/manual/${by}`);
        } else if (manauto === 1) {
          navigate.push(`/roomcode/manual/${by}/user2`);
          localStorage.setItem("f", id);
          getDataRoom(id, mode, by, "manual");
        } else {
          setLoading(true);
          getDataRoom(id, mode, by);
        }
      }
    } else {
      if (
        openroom.server_result === "awaiting" ||
        openroom.server_result === "pending"
      ) {
        toast.error("Room Code is Open, Please close it.");
      } else {
        toast.error("Wallet money is not enough to play game");
      }
    }
  };
  const getDataRoom = async (id, mode, by, manuauto) => {
    const data = {
      contest_fee: id,
      room_name: mode,
      challenge_id: by,
    };

    const requestOptions = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await fetch(`${url}get_room`, requestOptions);
      if (!response.ok) {
        const errorData = await response.json();
        localStorage.setItem("ro", errorData.data.room_code);
        dispatch(addRoomCode(errorData.data.room_code));
        localStorage.setItem("f", id);
        localStorage.setItem("ri", errorData.data.id);
      }
      let actualData = await response.json();
      localStorage.setItem("ri", actualData.data.id);
      localStorage.setItem("ro", actualData.data.room_code);
      localStorage.setItem("room", actualData.data.room_code);
      localStorage.setItem("f", id);
      dispatch(addRoomCode(actualData.data.room_code));
      if (manuauto !== "manual") {
        navigate.push(`/room-code/${actualData.data.room_code}/${mode}/${by}`);
      }
      setLoading(false);
      setLoadingSecond(false);
    } catch (err) {
      setError(err.message);
      // console.log(err.message);
    } finally {
    }
  };
  const handleGottoroomcode = () => {
    if (openroom.room_code) {
      localStorage.setItem("ri", openroom.id);
      localStorage.setItem("ro", JSON.stringify(openroom.room_code));
      navigate.push(
        `/room-code/${openroom.room_code}/${openroom.our_room}/${openroom.challenge_id}`
      );
      localStorage.setItem("f", openroom.contest_fee);
    }
  };

  const controller = new AbortController();
  const signal = controller.signal;
  const getData = async () => {
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: signal,
    };
    try {
      const response = await fetch(
        `${url}room-challenges/${param.mode}`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error(
          `This is an HTTP error: The status is ${response.status}`
        );
      }
      let actualData = await response.json();
      getDataCrm(actualData.data);

      setError(null);
    } catch (err) {
      setError(err.message);
      setGameList([]);
    } finally {
    }
  };
  const getDataCrm = async (data) => {
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: signal,
    };
    try {
      const response = await fetch(`${url}room-challenges/crm`, requestOptions);
      if (!response.ok) {
        throw new Error(
          `This is an HTTP error: The status is ${response.status}`
        );
      }
      let actualData = await response.json();
      const mergeArrayTemp = [...data, ...actualData.data];
      const temparray = [];
      mergeArrayTemp.sort((a, b) => b.id - a.id);
      const userIndex = mergeArrayTemp.findIndex(
        (item) => item.code_by === user.name
      );
      temparray.push(mergeArrayTemp[userIndex]);
      mergeArrayTemp.forEach((item, i) => {
        if (i !== userIndex && item?.code_by !== "") {
          temparray.push(item);
        }
      });
      setGameList(temparray);
    } catch (err) {
      setError(err.message);
      setGameList([]);
    } finally {
      setLoading(false);
    }
  };
  const getVS = async () => {
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: signal,
    };
    try {
      const response = await fetch(`${url}getvs/${param.mode}`, requestOptions);
      if (!response.ok) {
        throw new Error(
          `This is an HTTP error: The status is ${response.status}`
        );
      }
      let actualData = await response.json();
      setVsDisplay(actualData.data);
    } catch (err) {
      setError(err.message);
    } finally {
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      getData();
    }, 2000);
    const intervalForVs = setInterval(() => {
      getVS();
    }, 4000);
    getVS();
    getData();

    return () => {
      clearInterval(interval);
      clearInterval(intervalForVs);
      controller.abort();
    };
    // eslint-disable-next-line
  }, []);
  const createRoomCode = (e, priceValue) => {
    e.preventDefault();
    const crete = async () => {
      const data = {
        contest_fee: priceValue,
        room_name: priceValue < 1100 ? param.mode : "crm",
      };
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      };
      try {
        const response = await fetch(`${url}challenges`, requestOptions);
        if (!response.ok) {
          // console.log("error");
        }
        getData();
        let actucalData = await response.json();
        getDataRoom(
          actucalData.data.contest_fee,
          actucalData.data.room_name,
          actucalData.data.id
        );
        setLoading(true);
      } catch (err) {
        setError(err.message);
        setGameList([]);
      } finally {
      }
    };
    if (
      walletMoney.sumin - walletMoney.sumout >= priceValue &&
      openroom[0] === "room closed" &&
      priceValue >= 50
    ) {
      if (priceValue >= 50 && priceValue <= 10000) {
        crete();
        setLoadingSecond(true);
      } else {
        setLoadingSecond(false);
        toast.error(`Create A Room Between 50 to 10000 Coins`);
      }
    } else {
      if (
        openroom.server_result === "awaiting" ||
        openroom.server_result === "pending"
      ) {
        toast.error("Room Code is Open, Please close it.");
        setLoadingSecond(false);
      } else {
        if (priceValue < 50) {
          toast.error("Creata a Game more than 50");
          setLoadingSecond(false);
        } else {
          setLoadingSecond(false);
          toast.error("Wallet money is not enough to create game");
        }
      }
    }
  };
  const handleManualAuto = (e) => {
    e.preventDefault();
    handleUpdateChallange(priceValue, "manual")
  };
  const handleUpdateChallange = async (priceValue, manual) => {
    localStorage.setItem("f", priceValue);
    const data = {
      contest_fee: priceValue,
      room_name: priceValue < 1100 ? param.mode : "crm",
      is_manual: manual === "manual" ? 1 : 0,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(`${url}challenges`, requestOptions);
      if (!response.ok) {
        // console.log("error");
      }
      let actucalData = await response.json();
      setLoading(true);
      navigate.push(`/roomcode/manual/${actucalData.data.id}`);
    } catch (err) {
      setError(err.message);
    } finally {
    }
  };
  return (
    <Fragment>
      <Section page={"game"}>
        {!loading && (
          <React.Fragment>
            <div
              className="d-flex p-2 justify-content-center trans-bg px-3 align-items-center bg-white border  mx-3 border-3"
              style={{ borderRadius: "1.25rem", marginTop: "70px" }}
            >
              <h1 className="text-dark">Classic Mode </h1>
            </div>

            {openroom.room_code ? (
              <ul className="transaction-inner" style={{ cursor: "pointer" }}>
                <li
                  className="ba-single-transaction bg-red"
                  onClick={() => handleGottoroomcode()}
                >
                  <div className="thumb">
                    <img
                      className=""
                      src={process.env.PUBLIC_URL + "/assets/img/openroom.png"}
                      alt="img"
                      style={{ width: "60%" }}
                    />
                  </div>
                  <div className="details text-success">
                    <h5 className="mb-3 text-white">Room Code : Open</h5>
                    <p className="text-center px-4">Touch to Update </p>
                    <h5 className="amount text-dark">
                      <img
                        style={{ height: "30px" }}
                        src={process.env.PUBLIC_URL + "/assets/img/coin.png"}
                        alt="img"
                      />
                      {openroom.contest_fee}
                    </h5>
                  </div>
                </li>
              </ul>
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </React.Fragment>
        )}
        <ul className="transaction-inner" style={{ cursor: "pointer" }}>
          <form onSubmit={handleManualAuto}>
            <div className="d-flex p-2 h-56px">
              <input
                type="number"
                placeholder="50-10000 Coins"
                className="mx-2 max p-2 rounded"
                style={{ width: "60%" }}
                name="max"
                value={priceValue}
                onChange={(e) => setPriceValue(e.target.value)}
                required
                min={50}
                max={10000}
              />
              {loadingSecond ? (
                <button
                  className="btn btn-red py-0 btn-lg"
                  type="submit"
                  disabled
                >
                  Creating....
                </button>
              ) : (
                <button
                  className="btn btn-red py-0 btn-lg px-5 set-btn"
                  type="submit"
                >
                  Set
                </button>
              )}
            </div>
          </form>
          {loading ? (
            <div className="m-auto w-100 d-flex justify-content-center align-items-center h-60vh">
              <div className="loader"></div>
            </div>
          ) : (
            <React.Fragment>
              {gameList.length !== 0 ? (
                <React.Fragment>
                  {gameList?.map((item, i) => (
                    <Fragment key={i}>
                      {item?.code_by && (
                        <motion.li
                          key={i}
                          onClick={() =>
                            handleClick(
                              item.contest_fee,
                              item.id,
                              item.room_name,
                              item.code_by,
                              item?.is_manual
                            )
                          }
                          className="wrapper-animation"
                          animate={{ x: [10, 250, 0], opacity: 1, scale: 1 }}
                          transition={{
                            duration: 1,
                            delay: 0.3,
                            ease: [i, 0.71, 1, 1.5],
                          }}
                          initial={{ opacity: 0, scale: 0.5, x: 250 }}
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.9 }}
                        >
                          <div
                            className="ba-single-transaction"
                            id="slide-animation"
                          >
                            <div className="thumb">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/img/trophy.png"
                                }
                                alt="img"
                                style={{
                                  width: "70%",
                                  border: "1px solid #eee",
                                  padding: "5px",
                                  borderRadius: "15px",
                                }}
                              />
                            </div>
                            <div className="details text-success">
                              <h5 className="fw-light font-size">
                                {item?.code_by !== 0
                                  ? `Created By : ${item?.code_by}`
                                  : "Current Playing :209"}
                              </h5>
                              <p className="text-center black-gradient fw-light">
                                {item?.code_by === user.name
                                  ? "Delete"
                                  : "Touch to Play"}
                              </p>

                              <h5 className="amount text-success">
                                <img
                                  style={{ height: "30px" }}
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/img/coin.png"
                                  }
                                  alt="img"
                                />
                                {item?.contest_fee}
                              </h5>
                            </div>
                          </div>
                        </motion.li>
                      )}
                    </Fragment>
                  ))}
                </React.Fragment>
              ) : (
                <li className="ba-single-transaction">
                  <div
                    className="details text-success d-flex
                  justify-content-center
                  align-items-center"
                    style={{ maxWidth: "100%", gap: "15px" }}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/assets/img/trophy.png"}
                      alt="img"
                      style={{ width: "15%" }}
                    />
                    <h5 className="text-danger" style={{ fontSize: "0.85rem" }}>
                      Create or Join Room of your Choice
                    </h5>
                  </div>
                </li>
              )}
            </React.Fragment>
          )}
          {vsDisplay?.map((item, i) => (
            <GameListVs vsDisplay={item} token={token} key={i} />
          ))}
        </ul>
      </Section>
    </Fragment>
  );
};

export default GamePlayDirect;
