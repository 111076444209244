import React, { useEffect, useState } from "react";
import url from "../environemt";
import Section from "./layouts/Section";
import { useCookies } from "react-cookie";
const History = () => {
  // eslint-disable-next-line
  const [cookies, setCookie] = useCookies();
  const [tranactions, setTranactions] = useState([]);
  const [error, setError] = useState([]);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  const token = cookies.ludoM;
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: signal,
    };
    const getData = async () => {
      try {
        const response = await fetch(`${url}transaction_game`, requestOptions);
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        let actualData = await response.json();
        setTranactions(actualData.data);
        setError(null);
      } catch (err) {
        setError(err.message);
        setTranactions([]);
      } finally {
        setLoading(false);
      }
    };
    getData();
    // console.log(error);
    return () => controller.abort();
    // eslint-disable-next-line
  }, []);
  const handleGameFilter = () => {};
  return (
    <>
      <Section>
        <div
          className="d-flex p-2 justify-content-center trans-bg px-3"
          style={{ marginTop: "70px" }}
        >
          <h1>Game History</h1>
        </div>
        <div className="widget-body sliding-tabs">
          <ul className="nav nav-tabs" id="example-one" role="tablist">
            <li
              className="nav-item"
              id="all"
              onClick={(e) => handleGameFilter(e)}
            >
              <a
                className="nav-link active"
                id="base-tab-1"
                data-toggle="tab"
                href="#tab-1"
                role="tab"
                aria-controls="tab-1"
                aria-selected="true"
              >
                All
              </a>
            </li>
            <li
              className="nav-item"
              id="classic"
              onClick={(e) => handleGameFilter(e)}
            >
              <a
                className="nav-link"
                id="base-tab-2"
                data-toggle="tab"
                href="#tab-2"
                role="tab"
                aria-controls="tab-2"
                aria-selected="false"
              >
                Classic
              </a>
            </li>
            <li
              className="nav-item"
              id="rich"
              onClick={(e) => handleGameFilter(e)}
            >
              <a
                className="nav-link"
                id="base-tab-3"
                data-toggle="tab"
                href="#tab-3"
                role="tab"
                aria-controls="tab-3"
                aria-selected="false"
              >
                Rich
              </a>
            </li>
          </ul>
        </div>
        <div className="tab-content pt-3">
          <div
            className="tab-pane fade p-3 show active"
            id="tab-1"
            role="tabpanel"
            aria-labelledby="base-tab-1"
          >
            {loading ? (
              <div className="m-auto w-100 d-flex justify-content-center align-items-center h-60vh">
                <div className="loader"></div>
              </div>
            ) : (
              <>
                {tranactions?.map((item, i) => (
                  <div
                    className={
                      item.paymet_type !== "withdrow"
                        ? "single-goal green single-goal-one green-transaction"
                        : "single-goal red single-goal-one green-transaction"
                    }
                    key={i}
                  >
                    <div
                      className={
                        item.id % 2 === 0
                          ? "row green-transaction"
                          : "row red-transaction"
                      }
                    >
                      <div className="col-7 pr-0">
                        <div className="details">
                          <h6>{item.withdraw_date}</h6>
                          <p>{item.pay_date}</p>
                        </div>
                      </div>
                      <div className="col-5 pl-0">
                        <div className="circle-inner circle-inner-one d-flex flex-column">
                          {item.paymet_type !== "withdrow" ? (
                            <h6 className="goal-amount mb-2">
                              Rs{item.pay_amount}
                            </h6>
                          ) : (
                            <h6 className="goal-amount mb-2 text-danger">
                              Rs {item.pay_amount}
                            </h6>
                          )}
                          <div className="" data-value="0.30">
                            <div className="text-center"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
          <div
            className="tab-pane fade p-3"
            id="tab-2"
            role="tabpanel"
            aria-labelledby="base-tab-2"
          >
            {tranactions?.map((item, i) => (
              <div
                className={
                  item.paymet_type !== "withdrow"
                    ? "single-goal green single-goal-one green-transaction"
                    : "single-goal red single-goal-one green-transaction"
                }
                key={i}
              >
                <div
                  className={
                    item.id % 2 === 0
                      ? "row green-transaction"
                      : "row red-transaction"
                  }
                >
                  <div className="col-7 pr-0">
                    <div className="details">
                      <h6>{item.withdraw_date}</h6>
                      <p>{item.pay_date}</p>
                    </div>
                  </div>
                  <div className="col-5 pl-0">
                    <div className="circle-inner circle-inner-one d-flex flex-column">
                      {item.paymet_type !== "withdrow" ? (
                        <h6 className="goal-amount mb-2">
                          Rs{item.pay_amount}
                        </h6>
                      ) : (
                        <h6 className="goal-amount mb-2 text-danger">
                          Rs{item.pay_amount}
                        </h6>
                      )}
                      <div className="" data-value="0.30">
                        <div className="text-center"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div
            className="tab-pane fade p-3"
            id="tab-3"
            role="tabpanel"
            aria-labelledby="base-tab-3"
          >
            {tranactions?.map((item, i) => (
              <div
                className={
                  item.paymet_type !== "withdrow"
                    ? "single-goal green single-goal-one green-transaction"
                    : "single-goal red single-goal-one green-transaction"
                }
                key={i}
              >
                <div
                  className={
                    item.id % 2 === 0
                      ? "row green-transaction"
                      : "row red-transaction"
                  }
                >
                  <div className="col-7 pr-0">
                    <div className="details">
                      <h6>{item.withdraw_date}</h6>
                      <p>{item.pay_date}</p>
                    </div>
                  </div>
                  <div className="col-5 pl-0">
                    <div className="circle-inner circle-inner-one d-flex flex-column">
                      {item.paymet_type !== "withdrow" ? (
                        <h6 className="goal-amount mb-2">
                          Rs{item.pay_amount}
                        </h6>
                      ) : (
                        <h6 className="goal-amount mb-2 text-danger">
                          Rs{item.pay_amount}
                        </h6>
                      )}
                      <div className="" data-value="0.30">
                        <div className="text-center"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Section>
    </>
  );
};

export default History;
