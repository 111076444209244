import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import url from "../environemt";
import Section from "./layouts/Section";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import moment from "moment";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const UserVerificationLogin = () => {
  const navigate = useHistory();
  const [cookies, setCookie] = useCookies(["ludoT"]);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [resendDisabled, setResendDisabled] = useState(true);
  const [countdown, setCountdown] = useState(60);
  const date = moment().add(30, "days").calendar();
  const expire = new Date(date);
  // eslint-disable-next-line
  const [no, token] = cookies.ludoT.split("|");
  try {
    if (cookies.sg2 === 1) {
      navigate.push("/games-play/clm");
    }
  } catch (err) {
    // console.log(err);
  }

  useEffect(() => {
    let interval;
    if (countdown > 0) {
      interval = setInterval(() => {
        setCountdown((prevCount) => prevCount - 1);
      }, 1000);
    } else {
      setResendDisabled(false);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [countdown]);

  const handleResendOtp = async () => {
    if (localStorage.getItem("phoneNo")) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: localStorage.getItem("phoneNo"),
      };
      try {
        const response = await fetch(`${url}login`, requestOptions);
        if (!response.ok) {
          const errorData = await response.json();
          toast.error(errorData.data[0]);
        }
        let actualData = await response.json();
        setCookie("ludoT", actualData.data.token);
        toast.success("OTP Sent Successfully");
        setResendDisabled(true);
        setCountdown(60);
      } catch (err) {
        setError(err.message);
        // console.log(error);
      } finally {
        setLoading(false);
      }
    } else {
      navigate.push("/signin");
    }
  };

  const handleLoginsubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const data = {
      otp: e.target[0].value,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(`${url}otp_validate`, requestOptions);
      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.data.error);
      }

      let actualData = await response.json();

      // if (actualData.data.aname === null) {
      //   navigate.push("/signup2");
      //   toast.success(
      //     "Your Adhar Card is not verified, please verify your adhar number to process."
      //   );
      // } else {
      // //
      // removeToken("ludoM", { path: "/" });
      // removeToken("ludoT", { path: "/" });
      // removeToken("sg2", { path: "/" });
      // //
      toast.success("OTP Verified Successfully");
      setCookie("ludoM", token, { expires: expire });
      setCookie("sg2", 1, { expires: expire });
      localStorage.removeItem("phoneNo");
      navigate.push(`/games-play/${"clm"}`);
      // }
    } catch (err) {
      setError(err.message);
      // console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const handleFour = (e) => {
    if (e.target.value.length > e.target.maxLength) {
      setOtp(e.target.value.slice(0, e.target.maxLength));
    } else {
      setOtp(e.target.value);
    }
  };
  return (
    <Section allNotification={false} searchPopup={true} title={"Verification"}>
      <div className="ba-page-name text-center mg-bottom-40">
        <h3>Mobile OTP Verification</h3>
      </div>
      <div className="signin-area mg-bottom-35">
        <div className="container" onSubmit={handleLoginsubmit}>
          <form
            className="verification-inner text-center"
            style={{
              backgroundImage: "url(assets/img/bg/14.png)",
              borderRadius: "25px",
            }}
          >
            <h3>Enter 4 Digit OTP </h3>
            <input
              type="number"
              placeholder="...."
              maxLength={4}
              required
              onChange={handleFour}
              value={otp}
              className="mb-4"
            />
            <div className="verify-btn">
              <div className="single-checkbox-wrap  align-items-center mb-3">
                <div className="w-100" style={{ fontSize: "0.7rem" }}>
                  <span>By continuing, you agree to our </span>
                  <Link
                    to="term-legal"
                    style={{ fontSize: "0.788rem", width: "unset" }}
                    className="btn-link border-0 lh-1 bg-transparent "
                  >
                    Terms & Conditions.
                  </Link>
                  <p>
                    By continuing, I certify that I am at least 18 Years old.{" "}
                  </p>
                </div>
              </div>
              {loading ? (
                <button
                  style={{ width: "200px" }}
                  type="submit"
                  className="btn btn-black"
                  disabled
                >
                  Verifying...
                </button>
              ) : (
                <button
                  style={{ width: "200px" }}
                  type="submit"
                  className="btn btn-black"
                >
                  Verify
                </button>
              )}
            </div>
          </form>
          <div className="resend-otp text-center">
            {resendDisabled ? (
              <button
                className="btn-link"
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  textDecoration: "none",
                }}
                disabled
              >
                Resend OTP in {countdown} seconds
              </button>
            ) : (
              <button
                className="btn-link"
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
                onClick={handleResendOtp}
              >
                Resend OTP
              </button>
            )}
          </div>
        </div>
      </div>
    </Section>
  );
};

export default UserVerificationLogin;
