import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import url from "../environemt";
import Section from "./layouts/Section";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import secureLocalStorage from "react-secure-storage";

const Gamelist = () => {
  const [gameList, setGameList] = useState([]);
  // eslint-disable-next-line
  const [cookies] = useCookies([""]);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useHistory();
  const walletMoney = useSelector((state) => state.user.userWallet);
  const openRoomLoading = useSelector((state) => state.user.roomUpdate);
  const token = cookies.ludoM;
  const paramz = useParams();
  const getDataRoom = async (id, mode, by) => {
    const data = {
      contest_fee: id,
      room_name: mode,
      challenge_id: by,
    };

    const requestOptions = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await fetch(`${url}get_room`, requestOptions);
      if (!response.ok) {
        const errorData = await response.json();
        localStorage.setItem("ro", JSON.stringify(errorData.data.room_code));
        localStorage.setItem("ri", errorData.data.id);
        navigate.push(`/room-code/${errorData.data.room_code}/${mode}/${by}`);
        localStorage.setItem("f", id);
      }
      let actualData = await response.json();
      localStorage.setItem("ri", actualData.data.id);
      localStorage.setItem("ro", JSON.stringify(actualData.data.room_code));
      navigate.push(`/room-code/${actualData.data.room_code}/${mode}/${by}`);
      localStorage.setItem("f", id);
    } catch (err) {
      setError(err.message);
      // console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const getData = async () => {
      const requestOptions = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        signal: signal,
      };
      try {
        const response = await fetch(
          `${url}room-challenges/${paramz.mode}`,
          requestOptions
        );
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        let actualData = await response.json();
        getOnlinePlayer(actualData.data);
        secureLocalStorage.setItem("ch", JSON.stringify(actualData.data));
        setError(null);
      } catch (err) {
        setError(err.message);
        setGameList([]);
      } finally {
        setLoading(false);
      }
    };
    const getOnlinePlayer = async (data) => {
      const requestOptions = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        signal: signal,
      };
      try {
        const response = await fetch(`${url}useronline`, requestOptions);
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        let actualData = await response.json();
        const newGameList = data;
        const actualGame = [];
        Object.entries(actualData.data)?.forEach(([item, value]) => {
          newGameList
            .filter((x) => x.id === parseInt(item))
            ?.forEach((game) => {
              actualGame.push({ ...game, online: value });
            });
        });
        setGameList(actualGame);
        setError(null);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    getData();
    return () => controller.abort();
    // eslint-disable-next-line
  }, []);

  const openroom = useSelector((state) => state.user.openRoom);
  const handleClick = (id, by) => {
    if (
      walletMoney.sumin - walletMoney.sumout >= id &&
      openroom[0] === "room closed"
    ) {
      setLoading(true);
      getDataRoom(id, paramz.mode, by);
    } else {
      if (
        openroom.server_result === "awaiting" ||
        openroom.server_result === "pending"
      ) {
        toast.error("Room Code is Open, Please close it.");
      } else {
        toast.error("Wallet money is not enough to play game");
      }
    }
  };
  const handleGottoroomcode = () => {
    if (openroom.room_code) {
      localStorage.setItem("ri", openroom.id);
      localStorage.setItem("ro", JSON.stringify(openroom.room_code));
      navigate.push(
        `/room-code/${openroom.room_code}/${openroom.our_room}/${openroom.challenge_id}`
      );
      localStorage.setItem("f", openroom.contest_fee);
    }
  };
  return (
    <>
      <Section page={"game"}>
        {!loading && openRoomLoading && (
          <React.Fragment>
            <div
              className="d-flex p-2 justify-content-center trans-bg px-3 black-gradient"
              style={{ marginTop: "70px" }}
            >
              <h1>Classic Lite Mode Challenges</h1>
            </div>

            {openroom.room_code ? (
              <ul className="transaction-inner" style={{ cursor: "pointer" }}>
                <li
                  className="ba-single-transaction bg-red"
                  onClick={() => handleGottoroomcode()}
                >
                  <div className="thumb">
                    <img
                      className="w-100"
                      src={process.env.PUBLIC_URL + "/assets/img/openroom.png"}
                      alt="img"
                    />
                  </div>
                  <div className="details text-success">
                    <h5 className="mb-3 text-white">Room Code : Open</h5>
                    <p className="text-center px-4 black-gradient">
                      Touch to Update{" "}
                    </p>
                    <h5 className="amount text-dark">
                      <img
                        style={{ height: "30px" }}
                        src={process.env.PUBLIC_URL + "/assets/img/coin.png"}
                        alt="img"
                      />
                      {openroom.contest_fee}
                    </h5>
                  </div>
                </li>
              </ul>
            ) : (
              <></>
            )}
          </React.Fragment>
        )}
        <ul className="transaction-inner">
          {loading || !openRoomLoading ? (
            <div className="m-auto w-100 d-flex justify-content-center align-items-center h-60vh">
              <div className="loader"></div>
            </div>
          ) : (
            <>
              <h4 className="fs-6 text-center mt-3">Users Online Wating</h4>
              {gameList?.map((item, i) => (
                <li
                  className="ba-single-transaction"
                  key={i}
                  onClick={() => handleClick(item.contest_fee, item.id)}
                  style={{ cursor: "pointer" }}
                >
                  <div className="thumb">
                    <img
                      src={process.env.PUBLIC_URL + "/assets/img/trophy.png"}
                      alt="img"
                    />
                  </div>
                  <div className="details text-success">
                    <h5 className="mb-3">Current Playing :{item.online}</h5>
                    <p className="text-center black-gradient">Touch to Play</p>
                    <h5 className="amount text-success">
                      <img
                        style={{ height: "30px" }}
                        src={process.env.PUBLIC_URL + "/assets/img/coin.png"}
                        alt="img"
                      />{" "}
                      {item.contest_fee}
                    </h5>
                  </div>
                </li>
              ))}
              <h5 className="fs-6 text-center">
                Select Challenge to start the Game
              </h5>
              <li
                className="ba-single-transaction"
                onClick={() => handleClick("800", "26")}
                style={{ cursor: "pointer" }}
              >
                <div className="thumb">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/img/trophy.png"}
                    alt="img"
                  />
                </div>
                <div className="details text-success">
                  <p className="text-center black-gradient w-100">
                    Touch to Play
                  </p>
                  <h5 className="amount text-success">
                    <img
                      style={{ height: "30px" }}
                      src={process.env.PUBLIC_URL + "/assets/img/coin.png"}
                      alt="img"
                    />{" "}
                    800
                  </h5>
                </div>
              </li>
              <li
                className="ba-single-transaction"
                onClick={() => handleClick("900", "27")}
                style={{ cursor: "pointer" }}
              >
                <div className="thumb">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/img/trophy.png"}
                    alt="img"
                  />
                </div>
                <div className="details text-success">
                  <p className="text-center black-gradient w-100">
                    Touch to Play
                  </p>
                  <h5 className="amount text-success">
                    <img
                      style={{ height: "30px" }}
                      src={process.env.PUBLIC_URL + "/assets/img/coin.png"}
                      alt="img"
                    />{" "}
                    900
                  </h5>
                </div>
              </li>
              <li
                className="ba-single-transaction"
                onClick={() => handleClick("1000", "28")}
                style={{ cursor: "pointer" }}
              >
                <div className="thumb">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/img/trophy.png"}
                    alt="img"
                  />
                </div>
                <div className="details text-success">
                  <p className="text-center black-gradient w-100">
                    Touch to Play
                  </p>
                  <h5 className="amount text-success">
                    <img
                      style={{ height: "30px" }}
                      src={process.env.PUBLIC_URL + "/assets/img/coin.png"}
                      alt="img"
                    />{" "}
                    1000
                  </h5>
                </div>
              </li>
            </>
          )}
        </ul>
      </Section>
    </>
  );
};

export default Gamelist;
