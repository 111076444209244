import React, { useEffect, useState } from "react";
import url from "../environemt";
import Section from "./layouts/Section";
import { useCookies } from "react-cookie";
import CoinsImg from "./CoinsImg";
const WalletHistory = () => {
  // eslint-disable-next-line
  const [cookies, setCookie] = useCookies();
  const [tranactions, setTranactions] = useState([]);
  const [error, setError] = useState([]);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  const token = cookies.ludoM;
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: signal,
    };
    const getData = async () => {
      try {
        const response = await fetch(`${url}wallet`, requestOptions);
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        let actualData = await response.json();
        setTranactions(actualData.data);
        const newItem = actualData.data;
        for (var i = 0; i < newItem.length - 1; i++) {
          if (newItem[i].txn_id === newItem[i + 1].txn_id) {
            if (newItem[i].in_out === "O" && newItem[i + 1].in_out === "O") {
              newItem[i] = {
                ...newItem[i],
                pay_amnt: newItem[i].pay_amnt + newItem[i + 1].pay_amnt,
              };
              newItem.splice(i + 1, i + 1);
              setTranactions(newItem);
            } else if (
              newItem[i].in_out === "IN" &&
              newItem[i + 1].in_out === "IN"
            ) {
              newItem[i] = {
                ...newItem[i],
                pay_amnt: newItem[i].pay_amnt + newItem[i + 1].pay_amnt,
              };
              newItem.splice(i + 1, i + 1);
              setTranactions(newItem);
            }
          }
        }
        setTranactions(newItem);
        setError(null);
      } catch (err) {
        setError(err.message);
        setTranactions([]);
      } finally {
        setLoading(false);
      }
    };
    getData();
    // console.log(error);
    return () => controller.abort();
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <Section>
        <div
          className="d-flex p-2 justify-content-center trans-bg px-3"
          style={{ marginTop: "70px" }}
        >
          <h1>Wallet History</h1>
        </div>
        {loading ? (
          <div className="m-auto w-100 d-flex justify-content-center align-items-center h-60vh">
            <div className="loader"></div>
          </div>
        ) : (
          <React.Fragment>
            {tranactions?.toReversed().map((item, i) => (
              <div
                className={
                  item.payment_status === "failure"
                    ? "single-goal red single-goal-one green-transaction"
                    : item.payment_status === "processed"
                    ? "single-goal yellow single-goal-one"
                    : "single-goal green single-goal-one green-transaction"
                }
                key={i}
              >
                <div className="row" style={{ height: "72PX" }}>
                  <div className="col-7 pr-0">
                    <div className="details" style={{ padding: "4px 22px" }}>
                      <h6>{item.amount_from}</h6>
                      {/*<p> Status: {item.payment_status}</p>*/}
                      <p> Time: {item.amount_date}</p>
                    </div>
                  </div>
                  <div className="col-5 pl-0">
                    <div
                      className="circle-inner circle-inner-one d-flex flex-column"
                      style={{ width: "125px", padding: "22px 0px" }}
                    >
                      {item.in_out === "IN" ? (
                        <React.Fragment>
                          {item.payment_status === "processed" ? (
                            <React.Fragment>
                              <CoinsImg />
                              <h6 className="goal-amount mb-2 text-yellow">
                                {item.pay_amnt.toFixed(2)}
                              </h6>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <CoinsImg />
                              <h6
                                className={
                                  item.payment_status === "failure"
                                    ? "goal-amount mb-2 text-danger"
                                    : "goal-amount mb-2 text-success"
                                }
                              >
                                {item.pay_amnt.toFixed(2)}
                              </h6>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {item.payment_status === "processed" ? (
                            <React.Fragment>
                              <CoinsImg />
                              <h6 className="goal-amount mb-2 text-yellow">
                                -{item.pay_amnt.toFixed(2)}
                              </h6>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <CoinsImg />
                              <h6 className="goal-amount mb-2 text-danger">
                                -{item.pay_amnt.toFixed(2)}
                              </h6>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </React.Fragment>
        )}
      </Section>
    </React.Fragment>
  );
};

export default WalletHistory;
