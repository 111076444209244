import imageCompression from "browser-image-compression";
import React, { useState } from "react";
const RoomCodeWon = ({ handleUpdate, close, updateLoading }) => {
  const [fileurl, setFileURL] = useState("");
  const onChangeFile = async (e) => {
    const imageFile = e.target.files[0];

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: imageFile.size / 1024 / 1024 >= 0.9 ? 800 : 1800,
      useWebWorker: true,
      alwaysKeepResolution: true,
      maxIteration: 5,
      initialQuality: 1,
    };
    var compressedFile = await imageCompression(imageFile, options);
    if (imageFile.size / 1024 / 1024 <= 0.3) {
      compressedFile = imageFile;
    }

    setFileURL(URL.createObjectURL(compressedFile));
  };

  return (
    <div>
      <h1 className="text-center " style={{ fontSize: "1.9rem" }}>
        Update Game Result
      </h1>
      {fileurl && (
        <div className="d-flex justify-content-center my-2">
          <img src={fileurl} alt="win preview" style={{ width: "100px" }} />
        </div>
      )}
      <div>
        <form
          onSubmit={(e) => handleUpdate(e, "win")}
          className="d-flex flex-column"
        >
          <input
            type="file"
            placeholder="upload image"
            className="mx-5"
            onChange={(e) => {
              e.target.files[0] && onChangeFile(e);
            }}
          />
          {updateLoading ? (
            <button
              className="btn btn-green btn-sm py-0 rounded mx-3 mt-3 mb-1"
              type="submit"
              disabled
            >
              <i className="fa fa-spinner fa-spin p-0"></i> Yes, I Won
            </button>
          ) : (
            <button
              className="btn btn-green btn-sm py-0 rounded mx-3 mt-3 mb-1"
              type="submit"
            >
              Yes, I Won
            </button>
          )}
          <button
            className="btn btn-grey btn-sm py-0 rounded mx-3 mb-3 mt-0"
            onClick={() => close()}
            type="button"
          >
            Back
          </button>
        </form>
      </div>
    </div>
  );
};

export default RoomCodeWon;
