import React, { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";

import WheelComponent from "react-wheel-of-prizes";
import url from "../environemt";
import Section from "./layouts/Section";

const SpinWheel = () => {
  // eslint-disable-next-line
  const [cookies] = useCookies();
  const token = cookies.ludoM;
  const [error, setError] = useState([]);
  const [spinCount, setSpinCount] = useState({
    spin_count: 1,
  });
  const [spin, setSpin] = useState([]);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  const divRef = useRef();
  const segments = [
    "won 1",
    "better luck next time",
    "won 5",
    "better luck next time",
    "better luck next time",
    "won 2",
  ];
  const segColors = ["#EE4040", "#F0CF50", "#815CD1", "#3DA5E0", "#34A24F"];

  const onFinished = async (winner, id) => {
    const UpdateSPin = async () => {
      const requestOptions = {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await fetch(`${url}updSpin`, requestOptions);
        if (!response.ok) {
          const errorData = await response.json();
          toast.error(errorData.data.error);
        }
      } catch (err) {
        setError(err.message);
        // console.log(error);
      } finally {
        setLoading(false);
      }
    };
    UpdateSPin();
    setSpinCount((prev) => {
      const newState = prev;
      return newState;
    });
    var win = 0;
    if (winner === "better luck next time") {
      win = 0;
    } else {
      win = winner.slice(4, 5);
    }
    const data = {
      price: win,
      spin_id: id,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(`${url}game_spinner_winner`, requestOptions);
      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.data.error);
      }
      let actualData = await response.json();
      toast(
        `${actualData.data.price === 0
          ? "Sorry Try on Next Day"
          : "You win the " + actualData.data.price
        }`
      );
    } catch (err) {
      setError(err.message);
      // console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: signal,
    };
    const getData = async () => {
      try {
        const response = await fetch(`${url}Spin_Count`, requestOptions);
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        let actualData = await response.json();
        setSpinCount(actualData.data[0]);
        setSpin(actualData.data);
        setError(null);
      } catch (err) {
        setError(err.message);
        // console.log(error);
      } finally {
        setLoading(false);
      }
    };
    setTimeout(() => {
      getData();
    }, 100);
    return () => controller.abort();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: signal,
    };
    const getData = async () => {
      try {
        const response = await fetch(`${url}Spin_Count`, requestOptions);
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        setError(null);
      } catch (err) {
        setError(err.message);
        // console.log(error);
      } finally {
        setLoading(false);
      }
    };
    getData();
    return () => controller.abort();
    // eslint-disable-next-line
  }, []);
  return (
    <Section>
      <div style={{ marginTop: "75px" }}>
        <div className="widget-header bordered no-actions d-flex align-items-center justify-content-center text-center  mt-5 ">
          <h3>Spin Wheel</h3>
        </div>
      </div>
      <div>
        {loading ? (
          <div className="m-auto w-100 d-flex justify-content-center align-items-center h-60vh">
            <div className="loader"></div>
          </div>
        ) : (
          <React.Fragment>
            {spinCount.spin_count < 1 ? (
              <div
                className="shadow border m-3 mx-4 pt-4 bg-red h-50"
                ref={divRef}
              >
                <div className="d-flex vs-container">
                  <p className="px-4 text-center text-white">
                    <i className="fa-solid fa-exclamation"></i>
                    &nbsp; &nbsp;Your Daily Spin Has exhaust
                  </p>
                </div>
              </div>
            ) : (
              <React.Fragment>
                {spin?.map((item, i) => (
                  <WheelComponent
                    key={i}
                    segments={segments}
                    segColors={segColors}
                    onFinished={(winner) => onFinished(winner, item.id)}
                    primaryColor="black"
                    contrastColor="white"
                    buttonText="Spin"
                    isOnlyOnce={true}
                    size={200}
                    upDuration={500}
                    downDuration={600}
                    fontFamily="Arial"
                  />
                ))}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    </Section>
  );
};

export default SpinWheel;
