import React, { useEffect, useState } from "react";
import url from "../environemt";
import { motion } from "framer-motion";
import CoinsImg from "./CoinsImg";

export const GameListVs = ({ vsDisplay, token }) => {
  const [name, setName] = useState("");
  const [nameSecond, setNameSecond] = useState("");
  const controller = new AbortController();
  const signal = controller.signal;
  const requestOptionsSecond = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal: signal,
  };
  const getById = async (id1, id2) => {
    try {
      if (id1 !== null) {
        const response = await fetch(
          `${url}users/${id1}`,
          requestOptionsSecond
        );
        let actualData = await response.json();
        setName(actualData.data);
      }
      if (id2 !== null) {
        const responseSecond = await fetch(
          `${url}users/${id2}`,
          requestOptionsSecond
        );
        let actualDataSecond = await responseSecond.json();
        setNameSecond(actualDataSecond.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
    }
  };
  useEffect(() => {
    if (vsDisplay) {
      getById(vsDisplay.user_i, vsDisplay.user_2);
    }
    return () => controller.abort();
    // eslint-disable-next-line
  }, []);
  return (
    <motion.div
      animate={{ x: [10, 250, 0], opacity: 1, scale: 1 }}
      transition={{
        duration: 1,
        delay: 0.3,
        ease: [1, 0.71, 1, 1.5],
      }}
      initial={{ opacity: 0, scale: 0.5, x: 250 }}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.9 }}
      className="shadow border mx-2 pt-2 bg-white mb-3 wrapper-animation"
    >
      <div className="d-flex vs-container" style={{ height: "70px" }}>
        <div className="col-sm text-center d-flex flex-column align-items-center">
          <img
            src={process.env.PUBLIC_URL + "/assets/img/avtar.png"}
            alt="avtar"
            style={{ width: "55px" }}
          />
          <p>{name ? name.name.split(" ")[0] : "user.name"}</p>
        </div>
        <div className="col-sm text-center d-flex flex-column align-items-center justify-content-center">
          <img
            className="vss"
            src={process.env.PUBLIC_URL + "/assets/img/vs_icon.png"}
            alt="="
            style={{ width: "55px" }}
          />
          <p>
            <CoinsImg /> {vsDisplay.contest_fee}{" "}
          </p>
        </div>
        <div className="col-sm text-center  d-flex flex-column align-items-center">
          <img
            src={process.env.PUBLIC_URL + "/assets/img/avtar.png"}
            alt="avtar"
            style={{ width: "55px" }}
          />
          <p>{nameSecond ? nameSecond.name.split(" ")[0] : "Searching..."}</p>
        </div>
      </div>
    </motion.div>
  );
};
