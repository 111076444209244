import React, { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import url from "../environemt";
import Section from "./layouts/Section";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import "./signup.css";
const Signup = () => {
  const params = useParams();
  const navigate = useHistory();
  const [cookies, setCookie] = useCookies(["ludoT"]);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [phonevalue, setPhoneValue] = useState("");
  try {
    if (cookies.sg2 === 1) {
      navigate.push("/home");
    }
  } catch (err) {
    // console.log(err);
  }
  const handleLoginsubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const adhharNo = e.target[3].value;
    const data = {
      name: e.target[0].value,
      phone_no: e.target[1].value,
      email: e.target[2].value,
      adhar_no: e.target[3].value,
      refered_id: params.refer,
    };
    const phone = {
      phone_no: e.target[1].value,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(`${url}register`, requestOptions);
      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.data[0]);
      }
      let actualData = await response.json();
      setCookie("ludoT", actualData.data.token);
      setCookie("sg1", "1");
      localStorage.setItem("phoneNo", JSON.stringify(phone));
      toast.success("OTP Send Successfully ");
      navigate.push("/otp");
    } catch (err) {
      setError(err.message);
      // console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const handleMaxLength = (e) => {
    if (e.target.name === "phone") {
      if (e.target.value.length > e.target.maxLength) {
        setPhoneValue(e.target.value.slice(0, e.target.maxLength));
      } else {
        setPhoneValue(e.target.value);
      }
    }
  };
  return (
    <Section
      allNotification={false}
      searchPopup={true}
      title={"Signup"}
      sign={true}
    >
      <div className="ba-page-name text-center mg-bottom-40">
        <h3>SignUp</h3>
      </div>
      <div className="signin-area mg-bottom-35">
        <div className="container">
          <form className="contact-form-inner" onSubmit={handleLoginsubmit}>
            <label className="single-input-wrap">
              <span>
                {" "}
                Name <span style={{ color: "red", display: "inline" }}>*</span>
              </span>
              <input type="text" required placeholder="Your Name *" />
            </label>
            <label className="single-input-wrap">
              <span>
                Phone Number{" "}
                <span style={{ color: "red", display: "inline" }}>*</span>
              </span>
              <input
                onChange={handleMaxLength}
                name="phone"
                type="number"
                maxLength={10}
                minLength={10}
                required
                value={phonevalue}
                placeholder="98679XXXX9"
                autoComplete
              />
            </label>
            <label className="single-input-wrap">
              <span>Email (Optional) </span>
              <input type="email" placeholder="Your Email Id" />
            </label>

            <input type="hidden" value="" name="adhaar" />
            {params.refer && (
              <label className="single-input-wrap">
                <span>Refer Code </span>
                <input
                  type="text"
                  value={params.refer}
                  readOnly
                  className="form-control"
                />
              </label>
            )}
            <div className="single-checkbox-wrap  align-items-center mb-3">
              <div className="w-100" style={{ fontSize: "0.7rem" }}>
                <span>By continuing, you agree to our </span>
                <Link
                  to="term-legal"
                  style={{ fontSize: "0.788rem", width: "unset" }}
                  className="btn-link border-0 lh-1 bg-transparent "
                >
                  Terms & Conditions.
                </Link>
                <p>
                  By continuing, I certify that I am at least 18 Years old.{" "}
                </p>
              </div>
            </div>
            {loading ? (
              <button type="submit" className="btn btn-purple" disabled>
                Sending OTP...
              </button>
            ) : (
              <button type="submit" className="btn btn-purple">
                Register & Send OTP
              </button>
            )}
            <div className="single-checkbox-wrap mt-5">
              <p className="text-primary ">
                {" "}
                Do you have an Account?{" "}
                <Link
                  to="/signin"
                  className="btn btn-black text-white margin-top-0 ms-3 ml-3"
                >
                  Login
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </Section>
  );
};

export default Signup;
