import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Section from "./layouts/Section";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import url,{ paymentUrl,REACT_APP_MERCHANT_ID,REACT_APP_MERCHANT_KEY,REACT_APP_MERCHANT_INDEX } from "../environemt";
import { SHA256 } from "crypto-js";
import { toast } from "react-toastify";
import axios from "axios";
import { useCookies } from "react-cookie";

const Transaction = () => {
  const [loading, setLoading] = useState(true)
  const [pageData, setPageData] = useState({})
  const [time, setTime] = useState(10)
  // eslint-disable-next-line
  // eslint-disable-next-line
  const navigate = useHistory();
  const params = useParams()
  const [cookies] = useCookies([]);
  const token = cookies.ludoM;
  useEffect(() => {
    const handleSubmit = async () => {
      // eslint-disable-next-line
      const fullURL = "/pg/v1/status" + `/${REACT_APP_MERCHANT_ID}/${params.transactionId}` + REACT_APP_MERCHANT_KEY
      const checksum = SHA256(fullURL) + "###" + REACT_APP_MERCHANT_INDEX
      try {
        //`${paymentUrl}pg/v1/status/${REACT_APP_MERCHANT_ID}/${params.transactionId}`
        const response = await axios.post(`${url}phonePay_payment_status`,
        {
          transaction_id: params.transactionId ,
          merchant_id: REACT_APP_MERCHANT_ID ,
          csu: checksum,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            accept: "application/json",
            "Content-Type": "application/json",
          },
        }
        )
        // console.log(response)
        if (response) {
          setPageData(response.data)
          setLoading(false)
        } else if (!response.ok) {
          toast.error(response.message)
        }
      } catch (err) {
        // console.log(err)
        setLoading(false)
      }
    }
    handleSubmit();
    // eslint-disable-next-line
  }, []);
  useEffect(()=> {
    console.log(pageData)
  },[pageData])
  useEffect(() => {
    const interval = setInterval(() => {
      if (time > 0) {
        setTime(time - 1);
      }
      if (time === 0) {
        navigate.push("/home");
        clearInterval(interval);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [time]);
  return (
    <Section allNotification={false} searchPopup={true} title={"Transaction"}>
      <section
        className="text-gray-600 body-font"
        style={{ marginTop: "80px" }}
      >
        {loading ? (
          <div className="m-auto w-100 d-flex justify-content-center align-items-center h-60vh">
            <div className="loader"></div>
          </div>) :
          <div className="container px-4 py-24 mx-auto">
            <div className="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">
              <p className="leading-relaxed text-lg">Transaction Status</p>
              <span className="inline-block h-1 w-50 rounded bg-indigo-500 mt-8 mb-6"></span>
              <h2 className={pageData?.data?.responseCode === "SUCCESS" ? "text-green-900 font-medium title-font tracking-wider text-sm text-success" : "text-red-900 font-medium title-font tracking-wider text-sm text-red"}>
                {pageData?.data?.responseCode}
              </h2>
              <p className="text-gray-500">Amount :{(pageData?.data?.amount)/100}</p>
              <p className="text-gray-500">Transaction ID:{pageData?.data?.transactionId}</p>
              <p className="text-gray-500">Payment Type:{pageData?.data?.paymentInstrument?.type}</p>
              <p className="text-gray-500">Payment Gateway TransactionId ID:{pageData?.data?.paymentInstrument?.pgTransactionId}</p>
              <p className="text-gray-500">Payment Gateway ServiceTransactionId ID:{pageData?.data?.paymentInstrument?.pgServiceTransactionId}</p>
            </div>
            <div className="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">
              <h2 className={pageData?.data?.responseCode === "SUCCESS" ? "text-green-900 text-success fs-6" : "text-red-900 text-red fs-6"}>
                {pageData?.message}
              </h2>
              <p>
                Redirect to Home Page {time}
              </p>
            </div>
          </div>}
      </section>
    </Section>
  );
};

export default Transaction;
