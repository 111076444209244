import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import Section from "./layouts/Section";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import url, {
  paymentUrl,
  REACT_APP_MERCHANT_KEY,
  REACT_APP_MERCHANT_ID,
  REACT_APP_MERCHANT_INDEX,
} from "../environemt";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { SHA256 } from "crypto-js";
import { Buffer } from "buffer";

import axios from "axios";
const Wallet = () => {
  const navigate = useHistory();
  const inputRef = useRef(null);
  const [cookies] = useCookies([]);
  try {
    if (cookies.sg2 !== 1) {
      navigate.push("/");
    }
  } catch (err) {
    // console.log(err);
  }
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = cookies.ludoM;
  const sum = useSelector((state) => state.user.userWallet);
  const user = useSelector((state) => state.user.userData);
  const handleSubmit = async (amount, transactionId) => {
    const payload = {
      merchantId: REACT_APP_MERCHANT_ID,
      merchantTransactionId: transactionId,
      merchantUserId: user?.id,
      amount: amount * 100,
      redirectUrl: `http://www.ludogamers.com/transaction/${transactionId}`,
      redirectMode: "REDIRECT",
      callbackUrl: "https://www.ludogamers.com/transactions",
      mobileNumber: user?.phone_no,
      paymentInstrument: {
        type: "PAY_PAGE",
      },
    };
    const dataPayload = JSON.stringify(payload);
    // SHA56(base64 encoded payload + "/pg/v1/pay" + salt key) + ### + salt index
    const dataBase64 = Buffer.from(dataPayload).toString("base64");
    // eslint-disable-next-line
    const fullURL = dataBase64 + "/pg/v1/pay" + REACT_APP_MERCHANT_KEY;
    const checksum = SHA256(fullURL) + "###" + REACT_APP_MERCHANT_INDEX;
    try {
      // pg/v1/pay
      const response = await axios.post(
        `${url}phonePay_payment_process`,
        {
          request: dataBase64,
          csu: checksum,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("response",response);
      if (response) {
        const redirectUrl =
          response?.data?.data?.instrumentResponse?.redirectInfo?.url;
        window.location.replace(redirectUrl);
      } else if (!response.ok) {
        toast.error(response.message);
      }
    } catch (err) {
      // console.log(err.message)
      setLoading(false);
    }
  };
  const initialPayment = async (e) => {
    setLoading(true);
    e.preventDefault();
    const amount = e.target[0].value;
    const data = {
      amount: amount,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(`${url}phonePay_initiate`, requestOptions);
      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.message);
      }
      let actualData = await response.json();
      if (actualData) {
        handleSubmit(amount, actualData?.data?.txn_id);
      }
    } catch (err) {
      setError(err.message);
      // console.log(error);
      setLoading(false);
    }
  };

  const handleInitialPayment = async (e) => {
    const input = document.getElementById("input-add-coin");
    input.value = e;
  };
  return (
    <Section>
      <div
        className="d-flex p-2 justify-content-center trans-bg px-3 align-items-center bg-white border  mx-3"
        style={{ borderRadius: "1.25rem", marginTop: "70px" }}
      >
        <h1 className="text-dark">Wallet </h1>
      </div>
      <div className="border rounded border-dark m-3">
        <div className="containe  p-0">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header p-1 justify-content-center">
                <h5 className="modal-title ">Add Coins</h5>
              </div>

              <div className="modal-body p-0">
                <div className="action-sheet-content">
                  <form onSubmit={initialPayment}>
                    <div className="alert alert-danger" role="alert">
                      <strong>
                        <i className="fa fa-exclamation-triangle"></i>{" "}
                      </strong>{" "}
                      यदि आप किसी भी पेमेंट का बैंक से Complain डालते है तो आपके
                      खाते को Block कर दिया जायेगा ! इसलिए किसी और से अपने Ludo
                      ID में पैसे न डलवाये ! और यदि आप खुद जान भूझकर बैंक से
                      रिफंड लेने के लिए Complain डालते है तो आपकी Ludo ID
                      पूर्णतः बंद कर दी जाएगी !
                    </div>
                    <div className="text-center">
                      <h5 className="title inline text-center">Total Coins</h5>
                      <h5 className="amount inline">
                        {sum.sumin && (sum.sumin - sum.sumout).toFixed(2)}
                      </h5>
                    </div>
                    <div className="form-group basic">
                      {/* <label className="label">Enter Coin Amount</label> */}
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="input1">
                            <img
                              style={{ height: "30px" }}
                              src={
                                process.env.PUBLIC_URL + "/assets/img/coin.png"
                              }
                              alt="img"
                            />
                          </span>
                        </div>
                        <input
                          type="number"
                          className="form-control form-control-lg"
                          placeholder="Enter Coins"
                          required
                          ref={inputRef}
                          id="input-add-coin"
                        />
                      </div>
                    </div>
                    <PreDefineAddCoins
                      handleInitialPayment={handleInitialPayment}
                    />
                    <div className="text-center">
                      <p
                        style={{ fontSize: "10px" }}
                        className="title inline text-center"
                      >
                        By continuing, you agree to our{" "}
                        <Link to="term-legal"> Terms & Conditions </Link>
                      </p>
                    </div>
                    <div className="form-group basic">
                      {loading ? (
                        <button
                          type="submit"
                          className="btn-c btn-black btn-block btn-lg"
                          disabled
                          style={{
                            cursor: "pointer",
                            height: "25px",
                            lineHeight: "3px",
                          }}
                        >
                          Adding Coins.....
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn-c btn-black btn-block btn-lg fw-light "
                          style={{
                            cursor: "pointer",
                            height: "25px",
                            lineHeight: "3px",
                            fontWeight: 400,
                          }}
                        >
                          Add Coins
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WithDraw token={token} cookies={cookies} />
    </Section>
  );
};

export default Wallet;

const WithDraw = ({ token, cookies }) => {
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const withdraw = useSelector((state) => state.user.userWalletWith);
  const navigate = useHistory();
  const user = useSelector((state) => state.user.userData);

  const initialWithdraw = async (e) => {
    e.preventDefault();

    const lastCallTime = localStorage.getItem("lastWithdrawCall");
    if (lastCallTime && Date.now() - lastCallTime < 30 * 60 * 1000) {
      toast.error("You can only withdraw once in 30 minutes.");
      return;
    }

    if (user.adhar_no===null || user.adhar_no===undefined) {
      navigate.push("/signup2");
      toast.error(
        "Your Adhar Card is not verified, please verify your adhar number to process."
      );
      return;
    }
    if (e.target[0].value !== e.target[1].value) {
      toast.error("Please Enter The Same UPI Id ");
    } else {
      if (withdraw.sumin - withdraw.sumout < e.target[2].value) {
        if (withdraw.sumin - withdraw.sumout === 0) {
          toast.error("You Don't have enough Coins to withdraw");
          return;
        } else {
          toast.error(`Please Enter Below ${withdraw.sumin - withdraw.sumout}`);
          return;
        }
      } else {
        setLoading(true);
        const data = {
          upi_id: e.target[0].value,
          coins: e.target[2].value,
        };
        const requestOptions = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        };
        const upiData = {
          upi: e.target[0].value,
        };
        const requestOptionsUpiAdd = {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(upiData),
        };
        try {
          const response = await fetch(`${url}users`, requestOptionsUpiAdd);
          // console.log(response)
        } catch (error) {
          // console.log("erorr in addinig upi id", error.message)
        }
        try {
          const response = await fetch(
            `${url}intial_upi_payout`,
            requestOptions
          );
          if (!response.ok) {
            const errorData = await response.json();
            // console.log("error in withdraw ", errorData);
            toast.error(errorData.message);
          }
          let actualData = await response.json();
          setLoading(false);
          if (actualData.success) {            
            toast(actualData.message);
            localStorage.setItem("lastWithdrawCall", Date.now());
          }
        } catch (err) {
          setError(err.message);
          // console.log(error);
        } finally {
          setLoading(false);
        }
      }
    }
  };

  return (
    <div className="border  mx-3 border-dark rounded">
      <div className="container p-0">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header p-1 justify-content-center">
              <h5 className="modal-title">Withdraw Coins</h5>
            </div>
            <div className="modal-body p-0">
              <div className="action-sheet-content">
                <form onSubmit={initialWithdraw}>
                  <div
                    className="ba-cart-inner mb-3"
                    style={{ backgroundImage: "url('assets/img/bg/4.png')" }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      Withdrawl Limit
                    </p>
                    <span className="inline">
                      <h5>95</h5>
                      <p>Minimum</p>
                    </span>
                    <span className="inliner">
                      <h5>1,00,000</h5>
                      <p>Maximum</p>
                    </span>
                  </div>
                  <div className="text-center">
                    <h5 className="title inline text-center">
                      Available Coins:
                    </h5>
                    <h5 className="amount inline">
                      {withdraw.sumin &&
                        (withdraw.sumin - withdraw.sumout).toFixed(2)}
                    </h5>
                  </div>
                  <div className="form-group basic">
                    {/* <label className="label">Enter UPI ID</label> */}
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="UPI Id"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group basic">
                    {/* <label className="label">Confirm UPI ID</label> */}
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Confirm UPI Id"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group basic">
                    {/* <label className="label">Enter Coin Amount</label> */}
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="input1">
                          <img
                            style={{ height: "30px" }}
                            src={
                              process.env.PUBLIC_URL + "/assets/img/coin.png"
                            }
                            alt="img"
                          />
                        </span>
                      </div>
                      <input
                        type="number"
                        className="form-control form-control-lg"
                        placeholder="Enter Coins"
                        required
                      />
                    </div>
                  </div>
                  <div className="text-center">
                    <p
                      style={{ fontSize: "10px" }}
                      className="title inline text-center"
                    >
                      By continuing, you agree to our{" "}
                      <Link to="term-legal"> Terms & Conditions </Link>
                    </p>
                  </div>
                  <div className="form-group basic">
                    {loading ? (
                      <button
                        type="submit"
                        className="btn-c btn-black btn-block btn-lg"
                        disabled
                        style={{
                          cursor: "pointer",
                          height: "25px",
                          lineHeight: "3px",
                        }}
                      >
                        Withdrawing....
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn-c btn-black btn-block btn-lg"
                        style={{
                          cursor: "pointer",
                          height: "25px",
                          lineHeight: "3px",
                          fontWeight: 400,
                        }}
                      >
                        Withdraw
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const PreDefineAddCoins = ({ handleInitialPayment }) => {
  return (
    <div className="form-group basic">
      {/* <label className="label">Enter Coin Amount</label> */}
      <div className="input-group mb-3 d-flex justify-content-center">
        <div
          className="input-group-prepend input-group-hover"
          onClick={(e) => handleInitialPayment(50)}
          name="50"
        >
          <span className="input-group-text" id="input1">
            <span>50</span>
          </span>
        </div>

        <div
          className="input-group-prepend input-group-hover"
          onClick={(e) => handleInitialPayment(100)}
          name="100"
        >
          <span className="input-group-text" id="input1">
            <span>100</span>
          </span>
        </div>
        <div
          className="input-group-prepend input-group-hover"
          onClick={(e) => handleInitialPayment(200)}
          name="200"
        >
          <span className="input-group-text" id="input1">
            <span>200</span>
          </span>
        </div>
        <div
          className="input-group-prepend input-group-hover"
          onClick={(e) => handleInitialPayment(300)}
          name="300"
        >
          <span className="input-group-text" id="input1">
            <span>300</span>
          </span>
        </div>

        <div
          className="input-group-prepend input-group-hover "
          onClick={(e) => handleInitialPayment(500)}
          name="500"
        >
          <span className="input-group-text rounded-end" id="input1">
            <span>500</span>
          </span>
        </div>
      </div>
    </div>
  );
};
