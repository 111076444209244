import React from "react";
import { useHistory } from "react-router-dom";
import Section from "./layouts/Section";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ClMImage from "./Image/banner/classic-lite-mode.png";
import CRMImage from "./Image/banner/CLAASIC-RICH-MODE.png";
import PLMImage from "./Image/banner/POPULAR-LITE-MODE.png";
import PRMImage from "./Image/banner/POPULAR-RICH-MODE.png";
import QLMImage from "./Image/banner/QUICK-LITE-MODE.png";
import QRMImage from "./Image/banner/QUICK-RICH-MODE.png";
const Games = () => {
  const navigate = useHistory();
  const handleclick = (eve) => {
    if (eve === 1) {
      navigate.push(`/game-list/clm`);
    }
    //  else if (eve === 3) {
    //   navigate.push(`/game-list/plm`);

    // }
    // else if (eve === 5) {
    //   navigate.push(`/game-list/qlm`);

    // }
    else if (eve === 2) {
      navigate.push(`/game-rich/crm`);
    }
    // else if (eve === 4) {
    //   navigate.push(`/game-rich/prm`);
    // }
    // else if (eve === 6) {
    //   navigate.push(`/game-rich/qrm`);
    // }
  };

  return (
    <>
      <Section>
        <div
          className="d-flex p-2 justify-content-center trans-bg px-3 black-gradient"
          style={{ marginTop: "70px" }}
        >
          <h1>Games</h1>
        </div>

        <div className="d-flex p-4 game-list justify-content-center align-items-center">
          <div className="item" onClick={() => handleclick(1)}>
            <div className="single-blog">
              <div className="thumb">
                <LazyLoadImage src={ClMImage} alt="Image Alt" />
              </div>
            </div>
          </div>
          <div className="item" onClick={() => handleclick(2)}>
            <div className="single-blog">
              <div className="thumb">
                <LazyLoadImage src={CRMImage} alt="Image Alt" />
              </div>
            </div>
          </div>
          {/*opacity class to fade the image*/}

          <div className="item " onClick={() => handleclick(3)}>
            <div className="single-blog">
              <div className="thumb">
                <LazyLoadImage
                  src={PLMImage}
                  alt="Image Alt"
                  className="opacity"
                />
              </div>
            </div>
          </div>

          <div className="item " onClick={() => handleclick(4)}>
            <div className="single-blog">
              <div className="thumb">
                <LazyLoadImage
                  src={PRMImage}
                  alt="Image Alt"
                  className="opacity"
                />
              </div>
            </div>
          </div>
          <div className="item " onClick={() => handleclick(5)}>
            <div className="single-blog">
              <div className="thumb">
                <LazyLoadImage
                  src={QLMImage}
                  alt="Image Alt"
                  className="opacity"
                />
              </div>
            </div>
          </div>
          <div className="item " onClick={() => handleclick(6)}>
            <div className="single-blog">
              <div className="thumb">
                <LazyLoadImage
                  src={QRMImage}
                  alt="Image Alt"
                  className="opacity"
                />
              </div>
            </div>
          </div>
        </div>
      </Section>
    </>
  );
};

export default Games;
