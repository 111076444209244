import React from "react";

const RoomCodelost = ({ handleUpdate, close, updateLoading }) => {
  return (
    <div>
      <h1 className="text-center" style={{ fontSize: "1.8rem" }}>
        Update Game Result
      </h1>
      <div>
        <form
          onSubmit={(e) => handleUpdate(e, "lost")}
          className="d-flex flex-column"
        >
          <div>
            <input type="checkbox" required className="mx-3" />
            <label className="w-50 "> I Accept, I Lost the Game</label>
          </div>
          {updateLoading ? (
            <button
              className="btn btn-red btn-sm py-0 rounded mx-3 mt-3 mb-1"
              type="submit"
              disabled
            >
              <i className="fa fa-spinner fa-spin p-0"></i> Updating....
            </button>
          ) : (
            <button
              className="btn btn-green btn-sm py-0 rounded mx-3 mt-3 mb-1"
              type="submit"
            >
              I lost
            </button>
          )}
          <button
            className="btn btn-grey btn-sm py-0 rounded mx-3 mb-3 mt-0"
            type="button"
            onClick={() => close()}
          >
            Back
          </button>
        </form>
      </div>
    </div>
  );
};

export default RoomCodelost;
