import React, { useState } from "react";
import Section from "./layouts/Section";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import url from "../environemt";

const Contact = () => {
  const [cookies] = useCookies(["ludoM"]);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = cookies.ludoM;
  const handleContact = async (e) => {
    setLoading(true);
    e.preventDefault();
    // console.log(e.target[0].value);
    // console.log(e.target[1].value);
    // console.log(e.target[2].value);
    // console.log(e.target[3].value);
    const data = {
      name: e.target[0].value,
      email: e.target[1].value,
      phone: e.target[2].value,
      message: e.target[3].value,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(`${url}contact_us`, requestOptions);
      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.data.error);
      }
    } catch (err) {
      setError(err.message);
      setLoading(false);
      // console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Section allNotification={false} searchPopup={true} title={"Contact"}>
      <div
        className="transaction-area pd-top-36 mg-bottom-35"
        style={{ marginTop: "70px" }}
      >
        <div className="container">
          <form onSubmit={handleContact} className="contact-form-wrap">
            <ul>
              <li>
                <input type="text" placeholder="Your Name" req />
              </li>
              <li>
                <input type="text" placeholder="Email Address" required />
              </li>
              <li>
                <input type="text" placeholder="Phone Number" required />
              </li>
              <li>
                <textarea
                  type="text"
                  placeholder="Type Of Message"
                  required
                  style={{
                    border: "0",
                    padding: "20px 15px",
                    fontSize: "16px",
                    fontWeight: "500",
                    width: "100%",
                    color: "#474747",
                  }}
                />
              </li>
            </ul>
            {loading ? (
              <button
                type="submit"
                className="btn-large btn-purple w-100"
                disabled
              >
                Sending Message...
              </button>
            ) : (
              <button type="submit" className="btn-large btn-purple w-100">
                Send Message
              </button>
            )}
          </form>
        </div>
      </div>
    </Section>
  );
};

export default Contact;
