import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import url from "../environemt";
import Section from "./layouts/Section";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import moment from "moment";

const SignUPAdhaarOtp = () => {
  const navigate = useHistory();
  const [cookies, setCookie] = useCookies(["ludoT"]);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [otp, setOtp] = useState(false);
  const date = moment().add(30, "days").calendar();
  const [resendDisabled, setResendDisabled] = useState(true);
  const [countdown, setCountdown] = useState(60);
  const expire = new Date(date);
  // eslint-disable-next-line
  const [no, token] = cookies.ludoT.split("|");
  const sessionID = localStorage.getItem("ses");

  const handleUploadAadhaarData = async (adhaarData) => {
    //console.log(adhaarData);

    const addressFull = `${adhaarData.address.careOf} ${adhaarData.address.house} ${adhaarData.address.street} ${adhaarData.address.locality} ${adhaarData.address.landmark}, ${adhaarData.address.postOffice}, ${adhaarData.address.vtc}, ${adhaarData.address.district}, ${adhaarData.address.pin}`;
    const data = {
      address: addressFull,
      dateOfBirth: adhaarData.dateOfBirth,
      aname: adhaarData.name,
      photo: adhaarData.photo,
      adhar_no: localStorage.getItem("acno"),
    };
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(`${url}users_update`, requestOptions);

      let actualData = await response.json();
      // console.log(actualData);
    } catch (err) {
      setError(err.message);
      //console.log(error);
    } finally {
      setLoading(false);
    }
  };

  try {
    if (cookies.sga !== 1) {
      navigate.push("/signup2");
    }
  } catch (err) {
    // console.log(err);
  }

  useEffect(() => {
    let interval;
    if (countdown > 0) {
      interval = setInterval(() => {
        setCountdown((prevCount) => prevCount - 1);
      }, 1000);
    } else {
      setResendDisabled(false);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [countdown]);

  const handleResendOtp = async () => {
    if (localStorage.getItem("AadharData")) {
      setOtpLoading(true);
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: localStorage.getItem("AadharData"),
      };
      try {
        const response = await fetch(`${url}adhaar_otp`, requestOptions);
        if (!response.ok) {
          const errorData = await response.json();
          toast.error(errorData.data.error);
        }
        console.log(response);
        let actualData = await response.json();
        if (actualData.data.code === 200) {
          toast.success(actualData.data.message);
        } else {
          toast.error(actualData.data.message);
        }
        setResendDisabled(true);
        setCountdown(60);
      } catch (err) {
        setError(err.message);
        console.log(err);
      } finally {
        setOtpLoading(false);
      }
    } else {
      navigate.push("/signup2");
    }
  };

  const handleLoginsubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const data = {
      otp: e.target[0].value,
      session: sessionID,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(`${url}validate_aotp`, requestOptions);
      let actualData = await response.json();
      if (actualData.data.code === 200) {
        navigate.push("/");
        toast.success(actualData.data.message);
        document.cookie =
          "sga=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        setCookie("sgao", 1);
        setCookie("ludoM", token, { expires: expire });
        setCookie("sg2", 1, { expires: expire });
        handleUploadAadhaarData(actualData.data.data);
      } else {
        toast.error(actualData.data.message);
      }
    } catch (err) {
      setError(err.message);
      // console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const handleFour = (e) => {
    if (e.target.value.length > e.target.maxLength) {
      setOtp(e.target.value.slice(0, e.target.maxLength));
    } else {
      setOtp(e.target.value);
    }
  };
  return (
    <Section allNotification={false} searchPopup={true} title={"Verification"}>
      <br />
      <div className="ba-page-name text-center mg-bottom-40">
        <h3> Aadhaar OTP Verification</h3>
      </div>
      <div className="signin-area mg-bottom-35">
        <div className="container" onSubmit={handleLoginsubmit}>
          <form
            className="verification-inner text-center"
            style={{
              backgroundImage: "url(assets/img/bg/14.png)",
              borderRadius: "25px",
            }}
          >
            <h3>Enter Aadhaar OTP </h3>
            <input
              type="number"
              placeholder="......"
              maxLength={6}
              required
              onChange={handleFour}
              value={otp}
            />
            <div className="verify-btn">
              {loading ? (
                <button
                  style={{ width: "200px" }}
                  type="submit"
                  className="btn btn-black"
                  disabled
                >
                  Verifying...
                </button>
              ) : (
                <button
                  style={{ width: "200px" }}
                  type="submit"
                  className="btn btn-black"
                >
                  Verify Aadhaar
                </button>
              )}
            </div>
          </form>
          <div className="resend-otp text-center">
            {resendDisabled ? (
              <button
                className="btn-link"
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  textDecoration: "none",
                }}
                disabled
              >
                Resend OTP in {countdown} seconds
              </button>
            ) : otpLoading ? (
              <button
                className="btn-link"
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  textDecoration: "none",
                }}
                disabled
              >
                Sending OTP...
              </button>
            ) : (
              <button
                className="btn-link"
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
                onClick={handleResendOtp}
              >
                Resend OTP
              </button>
            )}
          </div>
        </div>
      </div>
    </Section>
  );
};

export default SignUPAdhaarOtp;
