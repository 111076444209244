import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Navbar from "./Navbar";
import Private from "../../privateRoute/Private";

const Section = ({ children, page }) => {
  return (
    <>
      <Private page={page}>
        <Navbar />
        <Header />
        {children}
        <Footer />
      </Private>
    </>
  );
};

export default Section;
