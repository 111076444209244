import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import url from "../environemt";
import {
  addOpenRoom,
  addSpin,
  addUpiId,
  addUser,
  addWallet,
  addWithdraw,
  roomstateUpdate,
} from "../store/slicer";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
const Private = ({ children, page }) => {
  const params = useParams();
  const navigate = useHistory();
  // eslint-disable-next-line
  const [cookies, setCookie, removeCookie] = useCookies(["ludoM"]);
  const dispatch = useDispatch();
  const path = useLocation();
  var token = cookies.ludoM;
  const [error, setError] = useState([]);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: signal,
    };
    const getData = async () => {
      try {
        const response = await fetch(`${url}grantSum`, requestOptions);
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        let actualData = await response.json();
        if (actualData.data) {
          dispatch(addWallet(actualData.data));
        }
        setError(null);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    const getUpiId = async () => {
      try {
        const response = await fetch(`${url}get_upi`, requestOptions);
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        let actualData = await response.json();
        if (actualData.data) {
          dispatch(addUpiId(actualData.data));
        }
        setError(null);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    const getUser = async () => {
      try {
        const response = await fetch(`${url}users`, requestOptions);
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        let actualData = await response.json();
        if (actualData.data[0]) {
          dispatch(addUser(actualData.data[0]));
          if(actualData.data[0].adhar_no){
            localStorage.setItem("acno", actualData.data[0].adhar_no);
          }
        }
        setError(null);
      } catch (err) {
        setError(err.message);
        // console.log(error);
      } finally {
        setLoading(false);
      }
    };
    const getWithDrawcoins = async () => {
      try {
        const response = await fetch(`${url}sum_winning`, requestOptions);
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        let actualData = await response.json();
        if (actualData.data) {
          dispatch(addWithdraw(actualData.data));
        }
        setError(null);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    const checkToken = async () => {
      try {
        const response = await fetch(`${url}check_token`, requestOptions);
        if (!response.ok) {
          removeCookie("ludoT", { path: "/" });
          removeCookie("ludoM", { path: "/" });
          removeCookie("sg2", { path: "/" });
          removeCookie("sg1", { path: "/" });
          setCookie("sg1", 1, { expires: new Date() });
          setCookie("ludoT", 1, { expires: new Date() });
          setCookie("ludoM", 1, { expires: new Date() });
          setCookie("sga", 1, { expires: new Date() });
          setCookie("sgao", 1, { expires: new Date() });
          toast.error("You are Not authrized");
        }
      } catch (err) {
        setError(err.message);
        // console.log(error);
      } finally {
        setLoading(false);
      }
    };
    const getOpenRoom = async () => {
      dispatch(roomstateUpdate(false));
      try {
        const response = await fetch(`${url}open_room`, requestOptions);
        if (!response.ok) {
          dispatch(
            addOpenRoom({
              room_code: null,
              u_1_result: null,
              u_2_result: null,
            })
          );
        }
        let actualData = await response.json();
        if (actualData.data) {
          dispatch(addOpenRoom(actualData.data));
        }
        setError(null);
      } catch (err) {
        setError(err.message);
        // console.log(error);
      } finally {
        setLoading(false);
        dispatch(roomstateUpdate(true));
      }
    };

    const getSpin = async () => {
      try {
        const response = await fetch(`${url}Spin_Count`, requestOptions);
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        let actualData = await response.json();
        dispatch(addSpin(actualData.data[0]));
        setError(null);
      } catch (err) {
        setError(err.message);
        // console.log(error);
      } finally {
        setLoading(false);
      }
    };
    if (
      path.pathname === "/signin" ||
      path.pathname === "/otp" ||
      path.pathname === "/signup" ||
      path.pathname === "/about" ||
      path.pathname === "/term-legal" ||
      path.pathname === "/signup2" ||
      path.pathname === "/adhaarotp" ||
      path.pathname === "/otplogin" ||
      path.pathname === `/signup/${params.refer}`
    ) {
    } else {
      if (page === "room" || page === "game") {
        getOpenRoom();
        getData();
        getUser();
        getOpenRoom();
        checkToken();
      } else if (path.pathname === "/home" || path.pathname === "/wallet") {
        getUser();
        getWithDrawcoins();
        getOpenRoom();
        getData();
        getUser();
        getSpin();
        checkToken();
        getUpiId()
      } else if (path.pathname === "/spin-wheel") {
        getSpin();
      } else if (page === "roomcode") {
        getOpenRoom();
        getUser();
      } else if (
        path.pathname === "/wallet" ||
        path.pathname === "/game-history"
      ) {
        getWithDrawcoins();
        checkToken();
        getData();
      } else if (path.pathname === "/user-setting") {
        getWithDrawcoins();
        checkToken();
        getData();
        getUser();
      }
    }
    return () => controller.abort();
    // eslint-disable-next-line
  }, []);
  if (
    path.pathname === "/signin" ||
    path.pathname === `/signup/${params.refer}` ||
    path.pathname === "/otp" ||
    path.pathname === "/signup" ||
    path.pathname === "/about" ||
    path.pathname === "/term-legal" ||
    path.pathname === "/otplogin" ||
    path.pathname === "/adhaarotp" ||
    path.pathname === "/signup2"
  ) {
    return children;
  } else if (cookies.sg2 === 1) {
  } else {
    // return <>{(window.location.reload(false), navigate.push("/"))}</>;
    return <>{navigate.push("/")}</>;
  }
  return children;
};

export default Private;
