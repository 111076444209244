import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import url from "../environemt";
import Section from "./layouts/Section";

const RefferalAndEarn = () => {
  // eslint-disable-next-line
  const [cookies, setCookie] = useCookies();
  const [earning, setTotalearning] = useState([]);
  const [error, setError] = useState([]);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  const token = cookies.ludoM;
  const coderefer = useSelector((state) => state.user.userData);
  const refercLink = `whatsapp://send?text=Play Ludo and earn Rs10000 daily.%0ACommission Charge - 5% Only%0AReferral - 2% On All Games%0A24x7 Live Chat Support%0AInstant Withdrawal Via UPI/Bank%0ARegister Now, My refer code is 298727008.%0A👇👇%0Ahttps://${window.location.host}/signup/${coderefer.id}`;

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: signal,
    };
    const getData = async () => {
      try {
        const response = await fetch(`${url}referalEarnings`, requestOptions);
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        let actualData = await response.json();
        setTotalearning(actualData.data);
        setError(null);
      } catch (err) {
        setError(err.message);
        // console.log(error);
      } finally {
        setLoading(false);
      }
    };
    getData();
    return () => controller.abort();
    // eslint-disable-next-line
  }, []);
  return (
    <Section>
      <div className=" col-12 mx-auto p-3 g-0" style={{ marginTop: "70px" }}>
        {loading ? (
          <div className="m-auto w-100 d-flex justify-content-center align-items-center h-60vh">
            <div className="loader"></div>
          </div>
        ) : (
          <>
            <div>
              <div className="mb-3 card">
                <div className="bg-light text-dark card-header">
                  Your Referral Earnings
                </div>
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex flex-column border-end flex-grow-1 align-items-center justify-content-center">
                      <span
                        className="text-capitalize fw-bold"
                        style={{ fontSize: "0.8rem" }}
                      >
                        Referred players
                      </span>
                      <span>
                        {earning.totalRefered ? earning.totalRefered : "0"}
                      </span>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center flex-grow-1">
                      <span
                        className="text-capitalize fw-bold"
                        style={{ fontSize: "0.8rem" }}
                      >
                        Referral Earning
                      </span>
                      <span>
                        <img
                          src={process.env.PUBLIC_URL + "/assets/img/coin.png"}
                          alt="coins"
                          style={{
                            width: "17px",
                            height: "17px",
                            marginRight: "4px",
                            marginTop: "1px",
                          }}
                        />
                        {earning.referalEarnings
                          ? earning.referalEarnings.toFixed(2)
                          : "0"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3 card">
                <div className="bg-light text-dark card-header">
                  Refer and Earn
                </div>
                <div className="card-body">
                  <div>
                    <div>
                      <div className="d-grid">
                        <a href={refercLink} target="_blank" rel="noreferrer">
                          <button className="btn btn-bg-green btn-md w-100 d-flex align-items-center justify-content-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 16 16"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              className="me-2"
                            >
                              <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                            </svg>
                            <span className="text-capitalize">
                              share on whatsapp
                            </span>
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3 card">
                <div className="bg-light text-dark card-header">
                  How It Works
                </div>
                <div className="card-body">
                  <ul className="list-group">
                    <li className="list-group-item">
                      You can refer and <b>Earn 2%</b> of your referral winning,
                      every time
                    </li>
                    <li className="list-group-item">
                      Like if your player plays for <b>₹10000</b> and wins, You
                      will get <b>₹200</b> as referral amount.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Section>
  );
};

export default RefferalAndEarn;
