import React, { useEffect, useState } from "react";
import Section from "./layouts/Section";
import { useCookies } from "react-cookie";
import url from "../environemt";

const About = () => {
  // eslint-disable-next-line
  const [cookies, setCookie] = useCookies();
  const token = cookies.ludoM;
  const [error, setError] = useState([]);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [about, setAbout] = useState([{ header: " ", content: " " }]);
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: signal,
    };
    const getData = async () => {
      try {
        const response = await fetch(`${url}aboutUs`, requestOptions);
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        let actualData = await response.json();
        setAbout(actualData.data);
        setError(null);
      } catch (err) {
        setError(err.message);
        setAbout([]);
      } finally {
        setLoading(false);
      }
    };
    getData();
    // console.log(error);
    return () => controller.abort();
    // eslint-disable-next-line
  }, []);
  return (
    <Section allNotification={false} searchPopup={true} title={"About"}>
      {/* transaction start */}
      <div
        className="transaction-area pd-top-36"
        style={{ paddingTop: "85px" }}
      >
        <div className="container">
          <div style={{ borderRadius: '1.25rem' }}
            className="section-title d-flex p-2 justify-content-center rounded-pill px-3 align-items-center bg-white border  mx-2 border-3">
            <h3 className="title ">About Us</h3>
          </div>

          <div className="about-content-inner">
            <div dangerouslySetInnerHTML={{ __html: about[0] && about[0].header }}>

            </div>
            <div dangerouslySetInnerHTML={{ __html: about[0] && about[0].content }}>

            </div>
            {/* <h5>{about[0] ? about[0].header : " "}</h5>
            <p>{about[0] ? about[0].content : " "}</p> */}
          </div>
        </div>
      </div>
      {/* transaction End */}
    </Section>
  );
};

export default About;
