import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import url from "../environemt";
import Section from "./layouts/Section";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { GameListVs } from "./GameListRichVs";

const GamelistRich = () => {
  const [gameList, setGameList] = useState([]);
  // eslint-disable-next-line
  const [vsDisplay, setVsDisplay] = useState([]);
  const [priceValue, setPriceValue] = useState("");
  // eslint-disable-next-line
  const [cookies] = useCookies();
  // eslint-disable-next-line
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingSecond, setLoadingSecond] = useState(false);
  const navigate = useHistory();
  const token = cookies.ludoM;
  const walletMoney = useSelector((state) => state.user.userWallet);

  const param = useParams();

  const openroom = useSelector((state) => state.user.openRoom);
  const handleClick = (id, by) => {
    if (
      walletMoney.sumin - walletMoney.sumout >= id &&
      openroom[0] === "room closed"
    ) {
      if (by === 0) {
        navigate.push(`/room-code/${id}/${param.mode}`);
      } else {
        setLoading(true);
        getDataRoom(id, param.mode, by);
      }
    } else {
      if (
        openroom.server_result === "awaiting" ||
        openroom.server_result === "pending"
      ) {
        toast.error("Room Code is Open, Please close it.");
      } else {
        toast.error("Wallet money is not enough to play game");
      }
    }
  };
  const getDataRoom = async (id, mode, by) => {
    const data = {
      contest_fee: id,
      room_name: mode,
      challenge_id: by,
    };

    const requestOptions = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await fetch(`${url}get_room`, requestOptions);
      if (!response.ok) {
        const errorData = await response.json();
        localStorage.setItem("ro", JSON.stringify(errorData.data.room_code));
        localStorage.setItem("f", id);
        localStorage.setItem("ri", errorData.data.id);
      }
      let actualData = await response.json();
      localStorage.setItem("ri", actualData.data.id);
      localStorage.setItem("ro", JSON.stringify(actualData.data.room_code));
      localStorage.setItem("f", id);
      navigate.push(`/room-code/${actualData.data.room_code}/${mode}/${by}`);
    } catch (err) {
      setError(err.message);
      // console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const handleGottoroomcode = () => {
    if (openroom.room_code) {
      localStorage.setItem("ri", openroom.id);
      localStorage.setItem("ro", JSON.stringify(openroom.room_code));
      navigate.push(
        `/room-code/${openroom.room_code}/${openroom.our_room}/${openroom.challenge_id}`
      );
      localStorage.setItem("f", openroom.contest_fee);
    }
  };

  const controller = new AbortController();
  const signal = controller.signal;
  const getData = async () => {
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: signal,
    };
    try {
      const response = await fetch(
        `${url}room-challenges/${param.mode}`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error(
          `This is an HTTP error: The status is ${response.status}`
        );
      }
      let actualData = await response.json();
      setGameList(actualData.data);
      setError(null);
    } catch (err) {
      setError(err.message);
      setGameList([]);
    } finally {
      setLoading(false);
    }
  };
  const getVS = async () => {
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: signal,
    };
    try {
      const response = await fetch(`${url}getvs/${param.mode}`, requestOptions);
      if (!response.ok) {
        throw new Error(
          `This is an HTTP error: The status is ${response.status}`
        );
      }
      let actualData = await response.json();
      setVsDisplay(actualData.data);
      setError(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      getData();
    }, 2000);
    getVS();
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);
  const createRoomCode = (e) => {
    e.preventDefault();
    setLoadingSecond(true);
    const crete = async () => {
      const data = {
        contest_fee: e.target[0].value,
        room_name: param.mode,
      };
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      };
      try {
        const response = await fetch(`${url}challenges`, requestOptions);
        if (!response.ok) {
          console.log("error");
        }
        getData();
        let actucalData = await response.json();
        getDataRoom(priceValue, param.mode, actucalData.data.id);

        setError(null);
      } catch (err) {
        setError(err.message);
        setGameList([]);
      } finally {
        setLoadingSecond(false);
      }
    };
    if (
      walletMoney.sumin - walletMoney.sumout >= e.target[0].value &&
      openroom[0] === "room closed" &&
      e.target[0].value >= 50
    ) {
      if (e.target[0].value >= 1100 && e.target[0].value <= 10000) {
        crete();
        setLoadingSecond(false);
      } else {
        setLoadingSecond(false);

        toast.error(`Create A Room Between 1100 to 10000 Coins`);
      }
    } else {
      if (
        openroom.server_result === "awaiting" ||
        openroom.server_result === "pending"
      ) {
        toast.error("Room Code is Open, Please close it.");
        setLoadingSecond(false);
      } else {
        if (e.target[0].value <= 50) {
          toast.error("Creata a Game more than 50");
          setLoadingSecond(false);
        } else {
          setLoadingSecond(false);
          toast.error("Wallet money is not enough to create game");
        }
      }
    }
  };

  return (
    <>
      <Section page={"game"}>
        {!loading && (
          <React.Fragment>
            <div
              className="d-flex p-2 justify-content-center trans-bg px-3 black-gradient"
              style={{ marginTop: "70px" }}
            >
              <h1>Classic Rich Mode Challenges</h1>
            </div>

            {openroom.room_code ? (
              <ul className="transaction-inner" style={{ cursor: "pointer" }}>
                <li
                  className="ba-single-transaction bg-red"
                  onClick={() => handleGottoroomcode()}
                >
                  <div className="thumb">
                    <img
                      className="w-100"
                      src={process.env.PUBLIC_URL + "/assets/img/openroom.png"}
                      alt="img"
                    />
                  </div>
                  <div className="details text-success">
                    <h5 className="mb-3 text-white">Room Code : Open</h5>
                    <p className="text-center px-4">Touch to Update </p>
                    <h5 className="amount text-dark">
                      <img
                        style={{ height: "30px" }}
                        src={process.env.PUBLIC_URL + "/assets/img/coin.png"}
                        alt="img"
                      />
                      {openroom.contest_fee}
                    </h5>
                  </div>
                </li>
              </ul>
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </React.Fragment>
        )}
        <ul className="transaction-inner" style={{ cursor: "pointer" }}>
          <form onSubmit={createRoomCode}>
            <div className="d-flex p-2">
              <input
                type="number"
                placeholder="1100-10000 Coins"
                className="mx-2 max p-2 rounded"
                style={{ width: "60%" }}
                name="max"
                value={priceValue}
                onChange={(e) => setPriceValue(e.target.value)}
              />
              {loadingSecond ? (
                <button
                  className="btn btn-primary py-0 btn-lg"
                  type="submit"
                  disabled
                >
                  Seting....
                </button>
              ) : (
                <button className="btn btn-primary py-0 btn-lg" type="submit">
                  Set
                </button>
              )}
            </div>
          </form>
          {loading ? (
            <div className="m-auto w-100 d-flex justify-content-center align-items-center h-60vh">
              <div className="loader"></div>
            </div>
          ) : (
            <React.Fragment>
              {gameList.length !== 0 ? (
                <React.Fragment>
                  {gameList
                    .slice(0)
                    .reverse()
                    ?.map((item, i) => (
                      <li
                        key={i}
                        onClick={() => handleClick(item.contest_fee, item.id)}
                        className="wrapper-animation"
                      >
                        <div
                          className="ba-single-transaction"
                          id="slide-animation"
                        >
                          <div className="thumb">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/img/trophy.png"
                              }
                              alt="img"
                            />
                          </div>
                          <div className="details text-success">
                            <h5 className="mb-3">
                              {item.code_by !== 0
                                ? `Created By : ${item.code_by}`
                                : "Current Playing :209"}
                            </h5>
                            <p className="text-center black-gradient">
                              Touch to Play
                            </p>

                            <h5 className="amount text-success">
                              <img
                                style={{ height: "30px" }}
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/img/coin.png"
                                }
                                alt="img"
                              />
                              {item.contest_fee}
                            </h5>
                          </div>
                        </div>
                      </li>
                    ))}
                  {vsDisplay?.map((item, i) => (
                    <GameListVs vsDisplay={item} token={token} key={i} />
                  ))}
                </React.Fragment>
              ) : (
                <li className="ba-single-transaction">
                  <div className="thumb">
                    <img
                      src={process.env.PUBLIC_URL + "/assets/img/trophy.png"}
                      alt="img"
                    />
                  </div>
                  <div className="details text-success">
                    <h5 className="amount text-danger">
                      Create or Join Room of your Choice
                    </h5>
                  </div>
                </li>
              )}
            </React.Fragment>
          )}
        </ul>
      </Section>
    </>
  );
};

export default GamelistRich;
